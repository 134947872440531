.aula-documentsSecureList-container,
.aula-documentsCommonList-container,
.aula-document-history-container {
  margin-bottom: 100px;
  .sort {
    margin: 0 5px 0 10px;

    /deep/ .dropdown-menu,
    /deep/ .dropdown-menu-right {
      left: auto !important;
      right: -15px;
    }

    /deep/ .icon-Aula_down-arrow {
      font-size: 8px;
      margin-left: 5px;
      top: -2px;
      position: relative;
    }

    .asc /deep/ a::before {
      font-family: 'aula', Lato, serif;
      content: '\e988';
      margin-right: 5px;
      margin-left: -14px;
    }

    .desc /deep/ a::before {
      font-family: 'aula', Lato, serif;
      content: '\e987';
      margin-right: 5px;
      margin-left: -14px;
    }
  }

  .filter {
    @include breakpoint-sm-down() {
      display: block;
      width: 100%;
    }

    /deep/ .left {
      display: inline-block;
      width: auto;
      padding-right: 24px;

      @include breakpoint-sm-down() {
        display: block;
        width: 100%;
      }
    }
  }

  h1 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;

    @include breakpoint-lg() {
      margin-bottom: 40px;
    }
  }

  .breadcrumbs {
    cursor: pointer;

    &:last-child {
      font-weight: 900;

      .breadcumb-spacer {
        font-weight: normal;
      }
    }
  }

  .document-title {
    display: flex;

    .file-document {
      width: auto;
      text-transform: unset;
      font-size: 18px;
      font-weight: 400;
      height: unset;
      margin-top: -3px;
      margin-left: 0;
      color: var(--color-primary-dark-guardian);
      .theme-employee & {
        color: var(--color-primary-dark-employee);
      }
    }
  }

  .breadcumb-spacer {
    padding: 0 10px;
    display: inline-block;
  }
}

.aula-documentsSecureList-container {
  @include breakpoint-sm-down() {
    margin: 16px 16px 0 16px;
  }
}

.theme-guardian {
  .sort,
  .sorter {
    .asc::before,
    .desc::before {
      color: $color-primary-base-guardian;
    }
  }
}

.theme-employee {
  .sort,
  .sorter {
    .asc::before,
    .desc::before {
      color: $color-primary-base-employee;
    }
  }
}

.theme-child {
  .sort,
  .sorter {
    .asc::before,
    .desc::before {
      color: $color-primary-base-child;
    }
  }
}

.aula-documentsSecureList-container,
.aula-documentsCommonList-container,
.aula-document-history-container {
  .table {
    .table-row {
      &.header {
        .table-cell {
          &.multi-select-header {
            min-width: 40px;
          }
          &.title-header {
            width: 25%;
          }
          &.category-header {
            width: 15%;
          }
          &.description-header {
            width: 25%;
          }
          &.regarding-children-header {
            width: 20%;
          }
          &.icon {
            span {
              .theme-guardian & {
                background-color: $color-primary-light-guardian;
              }

              .theme-employee & {
                background-color: $color-primary-light-employee;
              }

              .theme-child & {
                background-color: $color-primary-light-child;
              }
            }
          }
        }
      }
    }
  }
}

.btn {
  width: 137px;
  height: 50px;
}

.btn {
  margin-left: 10px;
  text-transform: uppercase;
  float: right;
}

.dropdown-select {
  list-style-type: none;
  padding: 0;
}
.progress {
  /deep/ .progress-bar {
    transition: width 0.3s ease;

    .theme-employee & {
      background-color: $color-primary-base-employee !important;
    }
    .theme-guardian & {
      background-color: $color-primary-base-guardian !important;
    }
    .theme-child & {
      background-color: $color-primary-base-child !important;
    }
  }
}
