























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.widget-W0062V0001 {
/* Override display:none with display:block */
input::-webkit-calendar-picker-indicator {
  display: block;
}
.momo-reminders {
  margin: 2px;
  background-color: white;
  border-radius: 4px;
  font-family: lato, sans-serif;
}

.momo-heading {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: grey solid 1px;
}

.momo-heading-text {
  font-weight: bold;
  font-size: 22px;
}

.momo-status-banner {
  position: absolute;
  top: 0;
  height: 32px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  opacity: 0.8;
}

.fade-leave-active {
  transition: opacity 3s;
}

.fade-leave-to {
  opacity: 0;
}

.momo-status-banner-success {
  background-color: #4BB543;
}

.momo-status-banner-failure {
  background-color: #C11E25;
}

.momo-status-banner-text {
  font-size: 16px;
  color: white;
}

.momo-favourite-teams-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0px;
}

.momo-user-reminders {
  margin-bottom: 4px;
}

.momo-user-reminders-no-more-to-see {
  padding-bottom: 12px;
}

.momo-child-name {
  padding: 8px;
  padding-left: 16px;
  font-size: 18px;
  font-weight: bold;
  background-color: #F6F7F8;
}

.momo-user-reminders-for-date:not(:last-child) {
  border-bottom: 1px solid RGBA(0, 0, 0, .08);
}

.momo-date-heading {
  padding-left: 16px;
  font-size: 15px;
  font-weight: bold;
  opacity: 0.54;
  min-height: 48px;
  line-height: 48px;
}

.momo-reminder {
  padding-left: 8px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
}

.momo-containing-block {
  transform: rotate(0deg); /* This makes this element the containing block of its children so that they can be absolutely positioned. See https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block */
}

.momo-reminder-icon {
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  background-color: #F2F2F2;
  border-radius: 4px;
}

.momo-team-reminder-icon-in-aula {
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  padding: 8px;
}

.momo-team-reminder-icon-in-momo {
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  padding: 8px;
}

.momo-assignment-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: #1e88e5;
  position: absolute;
  right: -4px;
  bottom: -4px;
  z-index: 1;
}

.momo-assignment-icon-in-aula {
  width: 23px;
  height: 23px;
}

.momo-assignment-icon-in-momo {
  width: 13px;
  height: 13px;
}

.momo-reminder-text-container {
  padding-left: 8px;
  flex-grow: 2;
}

.momo-reminder-text {
  font-size: 15px;
  opacity: 0.78;
  min-height: 18px;
  margin-bottom: 2px;
  margin-right: 2px;
  overflow-wrap: break-word;
}

.momo-reminder-additional-text {
  font-size: 12px;
  opacity: 0.54;
  min-height: 18px;
  display: flex;
  align-items: center;
}

.momo-icon-button {
  height: 20px;
  width: 20px;
  cursor: pointer;
  opacity: 0.54;
}

.momo-icon-spacer {
  margin-right: 16px;
}

.momo-icon-button:hover {
  opacity: 0.87;
}

.momo-no-reminders-for-user {
  padding: 8px;
}

.momo-pane {
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: #F6F7F8;
}

.momo-button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.momo-favourite-teams-heading {
  font-size: 15px;
  font-weight: bold;
  opacity: 0.54;
  min-height: 36px;
  line-height: 36px;
}

.momo-team-checkboxes {
  max-height: 200px;
  overflow-y: scroll;
  margin-bottom: 6px;
}

.momo-team-checkbox {
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: #F6F7F8 solid 1px;
}

.momo-team-checkbox-text {
  font-size: 15px;
  opacity: 0.78;
}

input {
  border-radius: 4px;
  background-color: white;
  font-family: lato, sans-serif;
  font-size: 15px;
  opacity: 0.78;
  padding: 6px;
  margin-bottom: 6px;
  border-style: none;
}

button {
  border-radius: 4px;
  border-style: none;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 12px;
  background-color: transparent;
  color: #222350;
  padding: 0 4px;
  outline: none;
  height: 36px;
  width: 60px;
  white-space: normal;
  cursor: pointer;
}

button:hover {
  background-color: RGBA(22, 23, 50, 0.08);
}

button:disabled {
  color: RGBA(22, 23, 50, 0.38);
}

.momo-delete-button-container {
  flex: 1 1 auto;
}

.momo-delete-button {
  color: #C11E25;
}

.momo-delete-button:hover {
  background-color: RGBA(193, 30, 25, 0.08);
}

.momo-message {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 8px;
  padding-right: 8px;
}

.momo-view-more-button {
  text-align: center;
  font-family: lato, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #F6F7F8;
}

.momo-view-more-button:hover {
  background-color: RGBA(22, 23, 50, 0.08);
}

option {
  background-color: red;
}
}
