





































.button-toggles.btn-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-template-rows: 1fr;
  gap: 8px;

  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn {
    border-radius: 6px;
  }

  .btn.btn-primary {
    background-color: var(--color-grey-base);
    color: var(--color-primary-darker);
    white-space: nowrap;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;

    &:hover {
      background-color: var(--color-grey-dark);
    }
    &[aria-selected='true'] {
      border-color: var(--color-primary-darker);
      &::before {
        font-family: 'aula', sans-serif;
        font-size: 1.5rem;
        line-height: 1rem;
        text-align: center;
        content: '\e91f';
        display: flex;
        justify-content: center;
        width: 12px;
        padding-right: 8px;
      }
    }
  }
}
