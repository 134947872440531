





























































































































































.widget-W0120V0001 {
  .mb-container .spinner-wrapper {
    min-height: 42px;
    padding: 10px 0;
  }

  .mb-container .mb-button {
    background-color: white !important;
    border: none !important;
    border-radius: 8px !important;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    max-width: 300px;
    color: #4D4D4D !important;
  }

  .mb-container .mb-utton:focus {
    box-shadow: none !important;
  }

  .mb-container .mb-button .button-logo {
    justify-content: center;
    align-items: center;
  }

  .mb-container .mb-button .logo {
    margin-right: 8px;
  }

  .error-message {
    color: #b50050;
  }
}
