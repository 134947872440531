





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.new-post-container {
  padding: 40px 0 0 0;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  background-color: $color-grey-light;

  @include breakpoint-lg-down() {
    padding: 0 20px 0 20px;
    position: fixed;
    max-width: unset;
    top: 0;
    width: 100%;
    bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    z-index: 1051;
  }
  .admin-warning {
    color: $color-conversation-left;
  }
}

.new-post-heading {
  position: relative;
  margin-bottom: 20px;
  i {
    font-size: 20px;
  }
  label {
    font-size: 18px;
  }
}

hr {
  margin-bottom: 0;
  margin-top: 15px;
}

.row {
  min-height: 60px;
}

.controls > div {
  font-size: 12.5px;

  &.checkbox {
    padding-left: 15px;
  }
  .custom-checkbox {
    margin-right: 5px;
    padding-top: 7px;
    /deep/ .custom-control-label::before,
    /deep/ .custom-control-label::after {
      top: -4px !important;
    }
  }
  input {
    width: auto;
  }
  /deep/ .el-date-editor {
    &.el-input,
    &.el-input__inner {
      width: 100%;
    }
  }
}

.submit-area {
  margin-top: 15px;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 30px;
}

.btn-light,
.btn-light:not([disabled]):not(.disabled) {
  &:hover,
  &:active {
    color: $color-grey;
    background-color: transparent;
    border: none;
  }
}

.drawer-modal /deep/ {
  .modal-dialog {
    max-width: 500px !important;
  }
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
}

/deep/ .aula-search {
  --select-input-tags-padding-right: 32px;
}
