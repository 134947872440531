









































































































































































































































































.widget-W0111V0001 {
.new-document-mark {
  color: #b50050;
  margin-left: 10px;
  font-size: 0.9rem;
}

.el-icon-loading {
  font-size: 1.5rem;
}

.pull-left {
  margin-right: 10px;
}

.text-container {
  text-align: center;
  padding: 30px;
}
}
