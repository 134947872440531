













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.calendar-toolbar {
  font-size: 14px;
  background-color: $color-grey-base;
  padding-left: 0;
  height: 50px;

  @include breakpoint-sm-down() {
    background-color: $color-white;
  }

  .navbar-nav {
    flex-direction: row;
    gap: 4px;
    .nav-item-right {
      position: absolute;
      right: 0px;
    }
    .icon-Aula_down-arrow {
      font-size: 9px;
    }
  }

  .open-calendar-section {
    padding: 0 12px 4px 12px;
  }
  .toolbar-label {
    font-size: 14px;
  }

  /deep/.dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label {
    text-transform: none;
    font-weight: normal;
    font-size: 12px;
  }

  .foldermenu {
    /deep/ .dropdown-menu {
      max-width: 500px !important;
      width: 500px !important;
      font-size: 14px;

      .el-select {
        --border-color: var(--color-grey-base);
      }
      .el-input {
        border-radius: 5px;
      }
      .el-input__inner {
        background-color: var(--color-white);
        border: none;
      }
    }
  }

  .more-actions {
    font-size: 16px;
    margin-right: 10px;
  }

  .dropdown-menu {
    .form-group {
      min-width: 190px;
      margin: 0;
      padding: 0 9px;

      .custom-checkbox {
        padding-bottom: 10px;
      }
    }
    input[type='text'] {
      border: solid 1px $color-form-border;
      padding: 8px 9px;
    }
  }
  .new-content-icon {
    position: relative;
    font-weight: bold;
    padding: 0 5px;
    height: 16px;
    top: -2px;
    background: $color-alert;
    line-height: 16px;
    display: inline-block;
    font-size: 12px;
    color: $color-grey-base;
    border-radius: 12px;
  }
  .icon-printer {
    font-size: 18px;
    margin-top: 2px;
    margin-right: 15px;
  }

  .tool-link,
  .dropdown-select {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    height: 38px;
    transition: background-color 150ms ease-in;

    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    &:hover {
      text-decoration: none;
      background-color: var(--color-grey-light);
    }
    .icon {
      position: relative;
      margin-right: 0.25rem;
    }
    .icon-Aula_down-arrow {
      font-size: 5px;
      position: relative;
      top: -1px;
      margin-left: 4px;
    }
    .icon-Aula_forward-arrow {
      font-size: 10px;
      margin-left: 4px;
    }

    .icon-Aula_folder {
      font-size: 17px;
    }
    .icon-Aula_write {
      top: 10px;
      font-size: 20px;
    }
    .dropdown-item {
      text-overflow: ellipsis;
      overflow: hidden;
      &.dropdown-folder {
        padding-left: 2.25rem;
      }
      &.active {
        font-weight: bold;
      }
    }
    .dropdown-header {
      text-align: center;
    }
    &.variable {
      /deep/ .dropdown-header {
        text-align: left;
        font-weight: bold;
      }
      /deep/ .dropdown-menu {
        width: 230px;
        max-width: 230px !important;
      }
    }
  }
  .is-not-empty {
    color: red !important;
  }
  .autoreply-text {
    display: none;
    @include breakpoint-xs-up() {
      display: initial;
      position: relative;
      bottom: 3px;
    }
  }
  .icon-Aula_settings {
    font-size: 19px;
  }
  .dropdown-select {
    .delegated-accesses {
      padding-left: 15px !important;
    }
    .non-selected-delegated-access {
      padding-left: 20px !important;
    }
    .selected-delegated-access {
      color: #45b7c1;
      font-size: 20px;
      bottom: -4px;
    }
  }
}

.hide-tablet-up {
  .icon-Aula_down-arrow {
    font-size: 5px;
    position: relative;
    top: -1px;
    margin-left: 4px;
  }
}

.mobile-calendar-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-width: 100vw;
  z-index: $mobile-calendar-toolbar-z-index;
  background-color: $color-grey-base;
  font-size: 14px;
  font-weight: bold;
  .theme-employee & {
    color: $color-primary-darker-employee;
  }
  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }
  .theme-child & {
    color: $color-primary-darker-child;
  }
  .theme-admin & {
    color: $color-primary-darker-admin;
  }
  .menu-header {
    position: relative;
    width: 100%;
    height: 57px;
    top: 0px;
    padding: 0;
    background-color: $color-primary-darker;
    font-weight: bold;
    .icon-Aula_arrow_new_item {
      color: $color-white;
      font-weight: 900;
      font-size: 28px;
      display: inline-block;
      line-height: 57px;
      vertical-align: top;
      float: left;
      margin: 0 -48px 0 20px;

      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }
    .text {
      color: $color-white;
      line-height: 57px;
      text-align: center;
      font-size: 14px;
    }
  }
  .menu-body {
    height: calc(100dvh - var(--menu-top-bar-height));
    overflow-x: hidden;
    overscroll-behavior: contain;
    padding-bottom: var(--menu-item-height);
    .toolbar-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 45px;
      width: 100%;
      background-color: $color-grey-base;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 29px;
      border-bottom: 1px solid $color-white;
    }
    .toolbar-item {
      width: 100%;
      background-color: $color-grey-lighter;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 49px;
      margin-bottom: 0px;
      border-bottom: 1px solid $color-white;
    }
  }
}

.add-user-modal-sm {
  .menu-header {
    position: relative;
    width: 100%;
    height: 57px;
    top: 0px;
    padding: 0;
    background-color: $color-primary-base;
    .row {
      height: 58px;
    }
    .header-text {
      margin: auto 5px auto 15px;
      color: $color-white;
      font-size: 14px;
      font-weight: bold;
    }
    .header-button {
      margin-left: -15px;
      margin-right: 5px;
      button {
        color: $color-white;
        height: 35px;
        max-width: 67px;
        border-radius: 5px;
        font-size: 13px;
        padding: 10px 0px;
        width: 48%;
      }
    }
    .text {
      color: $color-white;
      line-height: 57px;
      text-align: center;
      font-size: 14px;
    }
  }
  .menu-body {
    background-color: $color-grey-base;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 15px;
  }
}

.el-tag--info {
  margin: 2px;
}

.expand-large,
.expand-medium {
  display: none;
}

.collaps-large,
.collaps-medium {
  display: list-item;
}

.item {
  margin-bottom: 60px;
}

.copy-area {
  margin: 0px;
  padding: 12px 20px;
  background-color: $color-grey-light;
  flex-wrap: nowrap;
  align-items: center;

  p {
    flex: 1;
  }
}

.copy-btn {
  font-weight: 700;
  font-size: 15px;
  text-decoration: underline;
}

.static-item {
  position: static;
}

.consent-area {
  padding: 30px;
  background-color: $color-primary-base-admin;
}

.cancel-btn {
  float: right;
  height: 50px;
  padding: 14px 25px;
  margin-bottom: 3px;

  @include breakpoint-xl-down() {
    padding: 8px 15px;
  }
}

.aula-spinner {
  width: 70px;
  height: 70px;
  right: 46px;
  top: 8px;
}

.consent-mobile {
  padding: 12px;
}

@media only screen and (max-width: 1800px) {
  .collaps-large {
    display: none;
  }
}
@media only screen and (max-width: 1140px) {
  .expand-medium {
    display: list-item;
  }
  .collaps-medium {
    display: none;
  }
}
