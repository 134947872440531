

























































.widget-W0118V0001 {
button {
  color: #fff;
  background-color: rgb(69, 147, 194);
  border: 1px solid rgb(69, 147, 194);
  border-radius: 0;
  font-weight: 600;
  transition: none;
}

button:hover {
  color: rgb(255, 255, 255);
  background: rgb(40, 113, 159);
  border-color: rgb(40, 113, 159);
}

button:active {
  background: #18638F;
  border-color: #18638F;
}

button.active {
  background: #153854;
  border-color: #153854;
  pointer-events: none;
}

button[disabled="disabled"] {
  background-color: transparent !important;
  color: rgba(16, 16, 16, 0.3) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
  pointer-events: none !important;
}

.admin-theme button {
  background-color: #45B7C1;
  border: 1px solid #45B7C1;
}

.admin-theme button:hover {
  color: rgb(255, 255, 255);
  background: #2091A2;
  border-color: #2091A2;
}

.admin-theme button:active {
  background: #007A8D;
  border-color: #007A8D;
}

.admin-theme button.active {
  background: #222350;
  border-color: #222350;
  pointer-events: none;
}

.error-container {
  margin: 10px;
}

.foodplan-error {
  color: rgb(255, 255, 255);
  background: rgb(181, 0, 80);
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 4px;
}

.foodplan-container {
  background: #ffffff;
  padding: 10px;
  color: #222350;
}

.foodplan-child-name {
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  text-align: left !important;
  font-weight: 500 !important;
  color: #222350 !important;
  font-weight: 600 !important;
  margin-top: 20px;
  width: 100%;
}

.foodplan-child-name:hover {
  text-decoration: underline;
}

.foodplan-child-info {
  display: none;
}

.foodplan-child-info.expand {
  display: block;
}

.date {
  font-weight: 600;
  text-transform: capitalize;
  min-width: 70px;
  display: inline-block;
  float: left;
}

.week-select-buttons {
  display: flex;
}

.week-select-button {
  display: inline-block;
  margin: 0;
  padding: 10px 0px;
  flex: 1;
  transition: none;
}

.week-select-button:nth-child(2n+1) {
  border-right: none;
}

.week-select-date {
  font-size: 11px;
}

.product {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  display: grid;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  text-align: left !important;
  font-weight: 500 !important;
  color: #222350 !important;
}

.product:hover .product-name {
  text-decoration: underline;
}

.product span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.product-name {
  flex: 1;
}

.product:last-child {
  margin-bottom: 10px;
}

.product:nth-child(n+1) {
  margin-left: 70px;
}

.product.expand {
  white-space: normal;
}

.product-wrapper {
  display: flex;
}

.product-order-none {
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.product-order-none .product-name {
  opacity: 0.8;
  font-style: italic;
}

.foodplan-order-button,
.foodplan-login-button {
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}

.foodplan-container .fa-solid {
  font-family: 'Font Awesome 5 Pro';
  font-style: initial;
}

.foodplan-container .loading-container {
  padding: 40px;
  text-align: center;
}

.foodplan-container .loading-spinner {
  font-size: 30px;
  animation: loading-spinner 2s ease infinite;
  display: inline-block;
}

.mr-8 {
  margin-right: 8px;
}

@keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@-moz-keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@media screen and (min-width:992px) and (max-width:1200px) {
  .date {
    min-width: 65px !important;
  }

  .product:nth-child(n+1) {
    margin-left: 65px !important;
  }

  .foodplan-login-button {
    font-size: 14px;
  }

  .foodplan-child-name,
  .foodplan-container,
  .week-select-button {
    font-size: 14px;
  }
}
}
