


































































































































































































































































.widget-W0112V0001 {
  .main-section {
    border: 2px solid white;
    background-color: #FFF;
    height: calc(var(--innerViewHeightUnit, 1vh) * 100 - 141px);
    position: relative;
  }

  div.icon-rotator {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD5UlEQVRo3u2ZT4gbdRTHv++XJUzwT6HuoUrB0nhQKR6kerG9eCgL1l3FdT1ZpLB4kL1Edmf29rtlJpsuSPEgQvdiD6ZQdS1ioTf/gFUQ24KSS3qQIloKVXGSyWa+Hjq7JNNkMzOZcT3M9zZv5v3m98n7vfd+8wuQK1euXLlyJZekPaDW+mHDMI6TPCIijwPYB2ALwB2SvwH4rtPp/KC1/vt/B6K1njIMYx7AaQAvAiiMcekC+ALARrvd/lxr7e85iOM48yQdAIeT+JO8JiIrlmVdnmQeU0kd19fX93ued47k3CQTEJEnRaQ0yRhAwohUq9UnROQyRkdhC8BVki0R+V1EPJLTAJ4C8CwAI3jOE5E3TNP89D8HCSC+AnBgyO2fAKwXi8VLlUrlzjB/rfWDhmHMAlgSEScNiNggwXL6HvdH4raIVFzXPZ9G4iZRrBzxPO9cGEJEbiilZpeXl1t7AbAtFfVBx3HmAQwktojccF33hawgHMc5mCqI1noqKLH9uq2UmtVa/5kFRLVanSHZtG37ndRAgmYXXlKVrCJRrVZnROQTACUAZ6PARF1ap/svSF5zXfd8RhAnAojtEi0BzNJEILZt78O9bceORORMFtUpiMRnfRA7rwTw3m6RiRKRYxjcO20Vi8VLGUH0RyKs7cgMhRkLQvJIyHR1VLPLEGIszFgQpdShkOnmHkHsCjMWxPf9h0KmWylCnIgJEYZZ6jcAAGzbXgTwAZJtJD2l1GsrKyup5I5t22cAVPpMH1mW9eZuPjsRsSzrQwBLABjzvW2Sc2lBBHqs/4Lk2KY7sLQsy3o/Jkyb5Kurq6tfpggBAIdC1zdjgcSEyQSiXq9PA3guZL4eGyQiTFaRQK/XexmDfaurlPomEUgfzNtDYDyl1OtZQDQajQIGkxwArpim+VdikAAmXACySOwdtVqtU+EGTHIjiu/YPtK3zNyslhMA1Gq1Msl6yNwsl8sXo/hH7hmO4xw0TfPXLCCCjem3AJ4emJzInGmam6mCZKVarVb2fX9zCMQF0zQXoo6T+FxrUjUajUKr1Trl+34dwP7Q7SbJxTjjpRYRx3FeIWmJyFkAm6MqTb1en+52uyeVUu8O2VkDwK1CoXAs7tdnKiABxMcAioGpLSI/kvxZRP4gWRSRAyQPAziK0WfDzUKhMJPkEzqNs98wRCKJyAWSi5Zl3U3iP3GO+L7/D4BfROSZhEM0RWQ5anUa+UNMCgIAWmtVKpVOknwLwEsYH50ugCskN8rl8sWFhYXepHNIvfyura09QPJor9d7XkQeBfAIyQKAu7i3i73e6XS+TvuPnly5cuXKlWsS/QvhKq1sLYByvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMTozNjo1NSswMDowMPTXOyYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTE6MzY6NTUrMDA6MDCFioOaAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
    padding: 25px 25px 25px 25px;
    display: inline-block;
  }

  div.icon-eye {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABWUlEQVRIx+2UvUpDQRCFz6pJkUQw8RH8KUXBPhrwmYRgoWDkWoraSLDJC0hiLUb8KSX4DGoVtTRg+CzuCuEym9zYpMmB5cKeM2fn7syONMWk4UYJgDlJG5LWJJX8dlfSi6Rn59zPv04GVoFz4IswPoEzYGUc4wxwBPRIjx5QAzKjzEtA2zC4AspA3q8toGnoboFiyDwPPBlBu0MSqhr6ByBniS+tzD2XBY6BN+AViICs51pGXD1pvhO427LnI4OLPLcdiK0MHnAXEBU8/2Zw756bD8S2JWkmbXMZe33/HfqW/g44CPCb/tswuEZCk0xoP1mHuvGbzYEiR77AySJfG3EXVhflgEdDXA3eG+wZ+nuzTX1AkfixJNHy3VLwqxLI/AZYGF7NeFTUGG9UfAOHxIMxHYBl4JR4oIXwAZwASyGfNON6VtK64nG96Le7kjqSOs65/iiPKSaLX/pA8qZ4SFURAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEwLTA2VDExOjAxOjEzKzAwOjAwaHN9ngAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMC0wNlQxMTowMToxMyswMDowMBkuxSIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) no-repeat center center;
    padding: 35px 0 0 35px;
    display: inline-block;
  }

  .header-link {
    cursor: pointer;
    margin-left: 5px;
    line-height: 35px;
  }

  .icon-color-grey {
    color: #ccc !important
  }

  .icon-color-green {
    background-color: #96be2c
  }

  .infoba-header {
    color: #fff;
    padding: 4px;
    padding-left: 8px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: #873C79;
    position: relative;
  }

  .infoba-header:first-child {
    margin-top: 0
  }

  .infoba-header i {
    position: absolute;
    top: 2px;
    line-height: 35px;
    font-size: 24px !important
  }

  .infoba-header span {
    position: absolute;
    left: 34px;
    line-height: 35px;
    color: #fff;
    cursor: pointer;
    top: 5px
  }

  .quick-access-item {
    position: relative
  }

  .infoba-quick-access-item {
    background-color: #fff;
    //padding: 20px 10px 20px 35px;
    margin-top: 20px;
    text-align: center;
    font-style: italic;
    cursor: auto;
    height: auto
  }

  .infoba-link {
    color: #888 !important;
    cursor: pointer
  }

  .infoba-rotate {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite
  }

  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg)
    }

    to {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
    }
  }

  @keyframes rotating {
    from {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg)
    }

    to {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
    }
  }

  .infoba-logo {
    height: 35px
  }
}
