































































































































































































































































































































































































































































































.widget-W0070V0001 {
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .subit-widget-container {
    background-color: #f5f6fa;
    height: auto;
    font-size: 100%;
  }

  .details-header {
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #4D88FF;
    color: white;
    text-align: left;
    padding: 20px;
    margin-top: 0;
    padding-top: 13px;
    border-radius: 10px 10px 0 0;

  }

  .header-top {
    display: flex;
    align-items: center;
  }

  .date {
    margin-left: auto;
    display: flex;
  }

  .date span {
    padding: 0 20px;
  }

  .initial {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .initial span {
    font-size: 12px;
  }

  .full-name {
    padding: 0 10px;
    position: relative;
    font-size: 14px;
  }

  .header-bottom {
    padding: 0 20px 20px 20px;
    background: #4D88FF;
    overflow: hidden;
    overflow-x: auto;
  }

  .lesson-box {
    display: flex;
  }

  .lesson-box:last-child {
    margin-right: 0;
  }

  .calendar-left, .calendar-right {
    cursor: pointer;
  }

  .calendar-arrow-left {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .calendar-arrow-right {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .loading {
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
  }

  .details-content {
    margin: 0;
    background: white;
    min-height: 100px;
    border-radius: 0 0 10px 10px;
  }

  .teacher-assign-row {
    display: flex;
    flex-direction: column
  }

  .teacher-assign {
    display: flex;
    align-items: center;
    padding: 10px 1px 10px 10px;
  }

  .line {
    border-bottom: 1px solid #E6E7ED;
  }

  .arrow-down {
    margin-left: auto;
    cursor: pointer;
  }

  .unassign-reason, .note-message {
    padding-left: 10px;
    padding-bottom: 0;
  }

  .unassign-reason p::first-letter, .cancel-reason p::first-letter, span.para::first-letter {
    text-transform: uppercase;
    padding-left: 35px;
  }

  .no-lesson-container {
    background: #4D88FF;
    border-radius: 0 0 10px 10px;
    color: white;
    text-align: center;
    padding-bottom: 20px;
    font-size: 17px;
    height: auto;
    min-height: 100px;
  }

  .space {
    padding: 0 0.1em;
  }

  .teacher-notes {
    display: flex;
    padding: 15px;
  }

  .cancel-section {
    display: flex;
    padding: 15px;
    cursor: pointer;
  }

  .lessob-box-container {
    display: flex;
    width: max-content;
  }

  p.absolute-content {
    padding: 0 10px;
    background: white;
  }

  p.absolute-content.grey {
    color: #C0C5D0;
  }

  .active {
    background: white;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 0;
  }

  .content-wrap {
    background: white;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    color: #4D88FF;
    display: inline-flex;
    text-align: center;
  }

  .circle-line {
    position: relative;
    display: block;
    z-index: 1;
    border: 1px solid #E2E4EC;
    border-width: 0 0 0 1px;
    width: 3px;
    height: 23px;
    bottom: 0;
    top: auto;
    left: 4%;
  }

  .circle-line::after {
    top: 95%;
    right: 0;
    left: -8px;
    content: "";
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #E2E4EC;
    position: absolute;
  }

  .circle-with-content {
    display: flex;
  }

  .cancel-icon span {
    padding-left: 12px;
  }

  span.para {
    padding-top: 12px;
    color: #8C94A6;
  }

  .lesson-box > {
    display: inline-block;
    vertical-align: middle;
  }

  .title-padding {
    padding-left: 10px;
  }

  .cancel-reason {
    padding-left: 4px;
  }

  .relative-content {
    display: flex;
  }

  span.grey {
    color: #C0C5D0;
  }

  span.left {
    padding-right: 5px;
  }

  span.content-wrap {
    display: flex;
    flex-direction: column;
    padding: 6px;
  }

  .lesson-box > div {
    margin: 0 10px 0 0;
    width: auto;
  }

  .lesson-box > div:first-child {
    margin-left: 0;
  }

  span.relative-content {
    justify-content: center;
  }

  .dropdownMenu {
    position: absolute;
    top: 69px;
    bottom: 20px;
    left: 71px;
    right: 0;
    background: white;
    width: 215px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100px;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0px 3px 10px #888;
  }

  .dropdown-item li {
    cursor: pointer;
  }

  .currentSelected {
    background: rgb(228, 243, 255);
  }

  li.list-item {
    cursor: pointer;
    padding: 0 11px;
    margin-bottom: 10px;
  }

  li.list-item:active {
    color: black;
  }

  .fa-chevron-down {
    cursor: pointer;
    padding: 10px;
  }

  ul.dropdown-item {
    background: white;
    color: black;
    /*padding: 6px 12px;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  li.list-item.currentSelected {
    padding: 10px;
  }

  .footer {
    max-height: 25px;
    background: #F5F6FA;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
  }

  .footer .footer-item {
    margin-left: 14px;
    height: 100%;

    display: flex;
    align-items: center;
  }

  .footer .footer-item .footer-item-icon {
    fill: #6F7785;
    height: 14px;
    margin-right: 8px;
  }

  .footer .footer-item .footer-item-text {
    color: #6F7785;
    font-size: 12px;
  }
}
