#aula-comeandgo {
  background-color: $color-grey-light;

  @include breakpoint-lg-down() {
    background-color: $color-white;
  }

  .opening-hour-and-closed-day-container {
    margin-bottom: 60px;
  }

  .guardian-vacation-request-container,
  .guardian-vacation-announcement-container,
  .sick-report-container {
    margin-bottom: 70px;
    @include breakpoint-lg-down() {
      margin-bottom: 0;
    }
    .register-vacation-button {
      @include breakpoint-lg-down() {
        width: 100%;
      }
    }
    .missing-answer-badge {
      margin-left: -4px;
      font-weight: 900;
      color: $color-alert;
      display: flex;
      align-items: center;
      i {
        font-size: 1.6rem;
        font-weight: 900;
      }
    }
    .edit-button {
      width: 30px;
      height: 30px;
      font-size: 1.1rem;
      @include breakpoint-lg-down() {
        align-self: center;
      }
    }
    .absence-label {
      font-weight: 900;
      min-width: 110px;
    }
    .table {
      margin-bottom: 0;
      .table-row {
        &.header {
          display: table-row;
          .table-cell {
            cursor: default;
            padding: 0.75rem 1.25rem;
            font-weight: 900;
            font-size: 1rem;
            background-color: $color-grey-base;
            text-transform: unset;
            width: 50%;
            @include breakpoint-lg-down() {
              background-color: $color-white;
              padding: 0.75rem 0.25rem;
              border-width: 3px;
              white-space: normal;
            }
            @include breakpoint-sm-down(){
              background-color: $color-grey-light;
            }
            &:not(:last-of-type) {
              border-right: 3px solid $color-grey-light;
            }
          }
        }
        &.body {
          &:hover .table-cell {
            background-color: initial;
          }
          .table-cell {
            cursor: default;
            padding: 1rem 1.5rem;
            border-top: 3px solid $color-grey-light;
            vertical-align: baseline;
            &.pointer-cursor {
              cursor: pointer;
            }
            &:not(:last-of-type) {
              border-right: 3px solid $color-grey-light;
            }
            &:focus-visible,
            &:hover {
              background-color: $color-grey-lighter;
            }
            .cell-information {
              min-height: 30px;
              display: flex;
              align-items: center;
            }
            @include breakpoint-lg-down() {
              border-left: 0;
              border-right: 0;
              border-bottom: 0;
              padding: 0.5rem 0.5rem 0.5rem 1rem;
            }
            @include breakpoint-sm-down() {
              background-color: $color-grey-light;
            }
          }
        }
      }
    }
  }

  .guardian-vacation-request-container,
  .guardian-vacation-announcement-container {
    @include breakpoint-lg-down() {
      margin-bottom: 1rem;
    }
  }

  .presence-title {
    outline: none;
  }

  .config-sections {
    border-width: 0;
    border-color: hotpink;
    border-bottom-width: 2px;
    border-style: solid;
    margin-bottom: 20px;
    position: relative;

    @include breakpoint-lg-down() {
      border-bottom: 0;
      text-align: right;
      margin-top: 20px;
      margin-bottom: 0;
      margin-right: 10px;
    }

    .dropdown-select {
      /deep/ .dropdown-menu-right {
        top: -2px !important;
        left: auto !important;
        right: 0 !important;
        max-width: inherit !important;
      }

      /deep/ .dropdown-item {
        padding: 0.6rem 20px 0.6rem 28px;
      }

      /deep/ .dropdown-item.active::before {
        top: 12px;
      }

      @media only screen and (min-width: 1280px) {
        display: none;
      }

      .dropdown-toggle .icon {
        font-size: 6px;
        position: relative;
        top: -1px;
        left: 2px;
      }
    }
  }

  .nav-link {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    width: max-content;
    height: 32px;
    display: flex;
    align-items: center;

    @include breakpoint-lg-down() {
      font-weight: normal;
      font-size: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &.active::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: hotpink transparent transparent transparent;
      left: calc(50% - 8px);
      bottom: -9px;
      z-index: 1;
    }
  }

  .statistic {
    font-weight: 900;
  }

  .presence-absence {
    @include breakpoint-lg-down() {
      margin-top: 0;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 150px;
    }
    @include breakpoint-sm-down() {
      background: $color-grey-light;
    }
  }

  .box {
    &.absence {
      height: 100%;
    }

    .spinner-container {
      background-color: $color-white;
    }

    .box-title-container {
      @include breakpoint-lg-down() {
        background-color: $color-grey-light;
        .user-icon-mobile {
          position: relative;
        }
      }
    }
    .box-title {
      width: 100%;
      background-color: $color-grey-base;
      padding: 20px 30px;
      font-size: 20px;
      font-weight: 900;

      &.child-info-mobile {
        background-color: $color-grey-light;
        padding-left: 10px;
        padding-right: 10px;
      }

      @include breakpoint-lg-down() {
        background-color: $color-white;
        border-bottom: 1px solid $color-grey-base;
        padding: 25px 5px;
        font-size: 15px;
        cursor: pointer;
      }

      &.form {
        background-color: $color-primary-dark-guardian;
        color: $color-white;
      }
    }

    .box-content {
      background-color: $color-grey-light;
      height: calc(100% - 70px);

      .pickup-responsile-title {
        background-color: $color-grey-dark;
        padding: 15px 35px;
        font-weight: 900;
        height: 60px;
      }

      .pickup-responsile {
        background-color: $color-white;
        padding: 15px 35px;
        border-bottom: 1px solid $color-grey-base;
        height: 60px;
      }

      &.inline {
        display: flex;
      }
    }

    .child-sick-grid-container {
      display: grid;
      background-color: $color-grey-light;
      grid-template-columns: 1fr;
      grid-gap: 0;
      grid-auto-rows: 1fr;

      @include breakpoint-lg() {
        grid-template-columns: 1fr 1fr;
        grid-gap: 3px;
      }
    }
  }

  .child-sick {
    display: block;
    background-color: $color-white;
    padding: 25px 20px;
    width: 100%;

    @include breakpoint-lg-down() {
      padding-left: 5px;
    }

    @include breakpoint-sm-down() {
      background: $color-grey-light;
    }
  }

  .child-info {
    width: 100%;
    display: inline-block;

    .child-name {
      font-weight: 900;
      font-size: 16px;
      position: absolute;
      display: inline-block;
      margin-left: 15px;
      margin-top: 4px;
    }
  }

  .child-absence {
    display: block;
    padding: 30px;
    font-size: 16px;
    font-weight: 900;
  }

  .save-button {
    font-weight: 900;
    float: right;
    color: $color-primary-dark-guardian;
  }
  // list presences
  .presence-time {
    @include breakpoint-lg-down() {
      margin-top: 0;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: $menu-link-height;
    }

    .closed-day-text {
      color: $color-grey;
      font-weight: bold;
    }

    .closed-day-item {
      background-color: $color-grey-light;
      border: 2px solid $color-white;
    }

    .presence {
      .mobile-header {
        width: 100%;
        text-align: center;
        position: fixed;
        top: 0;
        background-color: $color-primary-darker-guardian;
        padding-top: 13px;
        height: 55px;
        font-size: 16px;
        color: $color-white;
        text-transform: uppercase;
        z-index: 100;

        .time-back {
          position: fixed;
          left: 15px;
          top: 20px;
        }

        .profile-icon {
          position: absolute;
          right: 15px;
          top: 10px;
          font-size: unset;

          /deep/ .user .small span {
            line-height: 50px !important;
          }
        }
      }

      @include breakpoint-lg-down() {
        position: absolute;
        top: -25px;
        left: 0;
        width: 100vw;
        z-index: $aula-presence-mobile-z-index;
        background-color: var(--color-grey-light);

        &.full-height {
          height: 100vh;
        }
      }

      .presence-week {
        display: block;

        .presence-header,
        .presence-content {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .item {
            margin: 2px;

            @include breakpoint-lg-down() {
              margin: 1px;
            }
          }

          .header-col {
            position: relative;
            padding: 13px 25px 10px 20px;
            background-color: $color-grey-base;
            font-weight: 900;

            .choose-week {
              background-color: $color-white;
              position: relative;
              left: 5px;
              font-size: 8px;
              padding: 4px 3px;
              border-radius: 3px;

              &:hover {
                background-color: $color-primary-darker-admin;
                color: $color-white;
              }
            }

            .presence-choose-week-picker {
              position: absolute;
              left: 115px;
              top: 30px;

              @include breakpoint-lg-down() {
                left: unset;
                right: 145px;
                top: 35px;
              }

              /deep/ .el-input__inner {
                display: none;
              }

              /deep/ .el-input__prefix {
                display: none;
              }
            }

            .presence-choose-week-icon {
              font-family: 'aula';
              font-size: 6px;
              position: relative;
              top: 0;
              left: 40px;
              background-color: $color-white;
              padding: 8px;
              border-radius: 4px;

              @include breakpoint-lg-down() {
                left: 10px;
              }

              &:hover {
                .theme-employee & {
                  background-color: $color-primary-darker-employee;
                }

                .theme-guardian & {
                  background-color: $color-primary-darker-guardian;
                }

                .theme-child & {
                  background-color: $color-primary-darker-child;
                }

                .theme-admin & {
                  background-color: $color-primary-darker-admin;
                }

                color: $color-white;
              }
            }

            &.header-profile {
              display: inline-block;

              .profile-name {
                position: relative;
                top: 2px;
                left: 7px;
              }
            }

            &.mobile {
              padding: 15px;
              width: 100%;

              .view-month {
                width: 50%;
                text-transform: uppercase;
              }

              .view-week {
                float: right;
                text-align: right;
                width: 50%;
              }
            }
          }

          .week {
            width: 160px;

            @include breakpoint-lg-down() {
              width: 70px;
            }
          }

          .coloumn1 {
            align-items: stretch;
            width: 90%;
          }

          .coloumn2 {
            align-items: stretch;
            width: 45%;
          }

          .coloumn3 {
            align-items: stretch;
            width: 30%;
          }

          .presence-date {
            width: 160px;
            padding: 20px 15px 10px 20px;
            background-color: $color-grey-base;

            @include breakpoint-lg-down() {
              width: 50px;
              text-align: center;
              padding-left: 10px;
            }

            &[aria-current="true"] {
              color: var(--color-white);
              background-color: var(--color-primary-darker);
            }
          }

          .presence-item:not(.closed-day-item) {
            background-color: $color-white;
          }

          .presence-item {
            display: flex;
            align-items: stretch;
            padding: 20px 15px 10px 20px;

            .presence-item-content {
              margin-right: auto;
            }

            .icon-edit-profile {
              font-size: 18px;
              cursor: pointer;
            }

            .icon-Aula_close {
              font-size: 12px;
              color: $color-alert;
            }
          }
        }

        @include breakpoint-lg-down() {
          padding-bottom: $menu-link-height;
        }
      }
    }
  }

  .presence-daily-overview {
    @include breakpoint-lg-down() {
      overflow: hidden;
      margin-top: -10px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: $menu-link-height;
    }

    .closed-day-text {
      color: $color-grey;
      font-weight: bold;
      font-size: 18px;

      @include breakpoint-lg-down() {
        font-size: 15px;
      }
    }

    .status-icon {
      width: 50px;
      height: 50px;
      background-color: $color-primary-light-guardian;
      color: $color-white;
      border-radius: 50%;
      position: absolute;
      top: 32px;
      left: 40px;
      z-index: 1030;
      text-align: center;
      font-size: 16px;
      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        &.icon-walking-person {
          font-size: 22px;
        }
        &.icon-Ferie_ikon,
        &.icon-Syg_ikon {
          font-size: 18px;
        }
        @include breakpoint-lg-down() {
          font-size: 12px;
          &.icon-walking-person,
          &.icon-backpack {
            font-size: 16px;
          }
          &.icon-Ferie_ikon,
          &.icon-Syg_ikon {
            font-size: 16px;
          }
        }
      }
      &.not-present {
        background: $color-attention;
        color: $color-primary-darker-employee;
        font-size: 18px;
      }
      &.sleeping,
      &.physical-placement {
        font-size: 22px;
        background: $color-primary-dark;
        color: $color-white;
      }
      &.present {
        background: $color-primary-dark;
        color: $color-white;
      }
      &.checked-out {
        background: $color-grey-light;
        color: $color-primary-darker-employee;
        border: 2px solid $color-primary-darker-employee;
        box-shadow: 0 0 0 2px $color-white;
      }
      &.sick,
      &.reported-absent {
        background: $color-grey-base;
        color: $color-primary-darker-employee;
      }
      &.fieldtrip,
      &.spare-time-activity {
        font-size: 22px;
        background: $color-primary-darker-guardian;
        color: $color-white;
      }

      @include breakpoint-lg-down() {
        padding-top: 4px;
        top: -5px;
        left: -15px;
        width: 30px;
        height: 30px;
        font-size: 14px;
      }
    }

    .user-icon {
      padding: 50px;
      width: 220px;
      position: relative;
      border-right: 1px solid $color-grey-base;

      &.large {
        .user {
          width: 120px;
          height: 120px;

          /deep/ .user-icon {
            width: 120px;
            height: 120px;

            .user-img {
              max-width: 122px;
              max-height: 122px;
            }

            span {
              font-size: 1.5em;
              width: 120px;
              height: 120px;
            }
          }
        }
      }
    }

    .user-activities {
      padding: 40px 40px 20px 40px;
      width: 78%;

      @include breakpoint-lg-down() {
        padding: 20px 30px 30px 30px;
        width: 100%;
      }
    }
  }
}

.filter-sorter .dropdown-toggle {
  .icon-Aula_down-arrow {
    font-size: 5px;
    position: relative;
    top: -1px;
  }
}

.mobile-box-title {
  text-transform: uppercase;
  font-weight: 700;
  background-color: $color-grey-dark;
  padding: 10px 20px;
  margin: 0 -20px;
  .opening-hour-and-closed-day-container & {
    margin: 0;
  }
}

.specific-opening-hour-container,
.general-opening-hour-container,
.holiday-closed-days-container {
  margin-bottom: 20px;

  @include breakpoint-lg() {
    margin-bottom: 50px;
  }
  .label {
    width: 110px;
    font-weight: bold;
  }

  .table {
    $weekday-header-width: 300px;

    width: 100%;
    margin-bottom: 0;
    table-layout: fixed;
    &.for-one-institution {
      width: calc((100% + #{$weekday-header-width}) / 2);
    }
    .modal-body & {
      width: 100vw;
      margin: 0 -19px;
    }
    .table-row {
      cursor: default;
      &.header {
        display: none;
        @include breakpoint-lg() {
          display: table-row;
        }
        .date-header,
        .weekday-header {
          width: #{$weekday-header-width};
          background-color: $color-grey-base;
        }
        .institution-header {
          width: 100%;
          background-color: $color-grey-base;
        }
        .table-cell {
          font-weight: 900;
          text-transform: unset;
          font-size: 1rem;
          &:not(:last-of-type) {
            border-right: 2px solid $color-grey-light;
          }
        }
      }

      &.body {
        .date-cell {
          background-color: $color-grey-base;
          @include breakpoint-lg-down() {
            width: 200px;
          }
        }
        .weekday-cell {
          background-color: $color-grey-base;

          @include breakpoint-lg-down() {
            text-transform: uppercase;
            background-color: $color-grey-base;
            width: 200px;
          }
        }
      }
    }
  }
}

.register-vacation-request-container,
.register-vacation-container {
  .aula-alert {
    position: relative;
    display: flex;
    line-height: 20px;
    background: $color-grey-base;
    min-height: 50px;
    border-radius: 5px;
    .alert-text {
      flex: 1;
      margin: auto 15px;
    }
    .icon-box-exclamation {
      width: 50px;
      max-height: 100%;
      background-color: $color-grey-dark;
      border-radius: 5px 0 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .circle {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 15px;
        }
      }
    }
  }
  .separating-line {
    padding-bottom: 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-grey-dark;
  }
  .field-label {
    font-weight: bold;
    width: 150px;
  }
  .vacation-intervals {
    margin-left: 15px;
    margin-right: 15px;
    min-width: 850px;

    @include breakpoint-xl-down() {
      min-width: 750px;
    }
    @include breakpoint-sm-down() {
      margin-left: -10px;
      margin-right: -10px;
    }

    .header-container {
      .header-item {
        display: flex;
        align-items: center;
        padding: 0.75rem 1rem;
        background-color: $color-grey-dark;
      }
    }
    .response-day {
      padding-top: 15px;
      .day {
        font-weight: 700;
        min-width: 100px;
      }
    }
    .checkin-time {
      padding: 10px;
      .time {
        background: $color-grey-light;
        .el-input {
          background-color: $color-grey-light;
        }
      }
    }

    @include breakpoint-lg-down() {
      min-width: unset;

      .response-day {
        padding-top: 2px;
        .day {
          background-color: var(--color-grey-base);
        }
        .custom-control.custom-checkbox {
          margin-bottom: 5px;
        }
      }

      .checkin-time {
        .time {
          .el-input {
            .el-input__inner {
              background: red;
              color: red;
            }
          }
        }
      }
    }
  }
}

.presence-drawer-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: var(--color-darkblue);

  @include breakpoint-sm-up() {
    color: var(--color-white);
    justify-content: flex-start;
  }
}

.drawer-edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-darkblue);
  margin-right: 4px;

  @include breakpoint-sm-up() {
    color: var(--color-white);
  }
  &.to-the-right {
    right: -30px !important;
  }
}

.register-absence-modal {
  /deep/ .modal-aula {
    transform: translate(-50%, 0) !important;

    @include breakpoint-lg-down() {
      transform: unset !important;
    }
  }

  /deep/ .modal-title {
    margin-left: 30px;
  }

  .register-absence {
    display: block;
    width: 50vw;
    min-height: 450px;

    &.pick-up-responsible {
      @include breakpoint-lg() {
        width: 450px;
        min-height: 250px;
      }
    }

    @include breakpoint-lg-down() {
      width: 100%;
      height: 100vh;
      background-color: $color-grey-light;
      max-height: unset;
      overflow: auto;
      padding-bottom: 100px;
    }

    .register-absence-form {
      display: block;
      padding: 15px 15px 30px 15px;
      width: 90%;
      margin: auto;

      @include breakpoint-tablet-landscape() {
        padding: 5px;
        width: 100%;
      }

      @include breakpoint-lg-down() {
        width: 100%;
      }

      .title {
        font-weight: bold;
        font-size: 22px;

        @include breakpoint-lg-down() {
          font-size: 16px;
          text-align: center;
          text-transform: uppercase;
        }
      }

      .child-select {
        display: block;
      }

      .editing-profile {
        display: inline-block;

        .profile-name {
          font-size: 20px;
          font-weight: bold;
          position: relative;
          top: 10px;
          left: 20px;

          @include breakpoint-lg-down() {
            font-size: 16px;
            top: 3px;
            left: 10px;
          }
        }
      }

      .mobile-short-date {
        text-transform: capitalize;
      }

      .absence-input {
        width: 100%;
      }

      .suggested-names-container {
        position: absolute;
        background: white;
        color: $color-darkblue;
        border-radius: 5px;
        list-style: none;
        font-size: 16px;
        z-index: 1060;
        padding: 6px 0;
        width: 90%;
        border: 1px solid $color-grey-light;
        box-shadow: 0 1px 4px 0 rgba(166, 166, 166, 0.5);

        li {
          padding: 0 20px;

          &:hover {
            background-color: $color-grey-light;
            color: $color-darkblue;
            cursor: pointer;
          }
        }
      }

      .white-border-bottom {
        @include breakpoint-lg-down() {
          border-bottom: 1px solid $color-white;
        }
      }

      .mobile {
        @include breakpoint-lg() {
          margin-top: 20px;
        }

        @include breakpoint-lg-down() {
          padding-bottom: 30px;
          padding-top: 15px;
          border-bottom: 1px solid $color-white;
        }
      }

      .aula-spinner {
        top: -10px;
        right: 40px;
      }
    }
  }
}

/deep/ .user {
  display: block;
  float: left;

  .small span {
    line-height: 50px !important;
  }
}

.theme-guardian {
  #aula-comeandgo {
    color: $color-primary-darker-guardian;

    .config-sections {
      border-color: $color-primary-darker-guardian;

      .nav-item {
        color: $color-primary-dark-guardian;

        .active,
        &:hover {
          color: $color-primary-darker-guardian;
        }

        .active::after {
          border-color: $color-primary-darker-guardian transparent transparent transparent;
        }
      }
    }
  }
}

.theme-employee {
  #aula-comeandgo {
    color: $color-primary-darker-employee;

    .config-sections {
      border-color: $color-primary-darker-employee;

      .nav-item {
        color: $color-primary-dark-employee;

        .active,
        &:hover {
          color: $color-primary-darker-employee;
        }

        .active::after {
          border-color: $color-primary-darker-employee transparent transparent transparent;
        }
      }
    }
  }
}

.theme-child {
  #aula-comeandgo {
    color: $color-primary-darker-guardian;

    .config-sections {
      border-color: $color-primary-darker-child;

      .nav-item {
        color: $color-primary-base-child;

        .active,
        &:hover {
          color: $color-primary-darker-child;
        }

        .active::after {
          border-color: $color-primary-darker-child transparent transparent transparent;
        }
      }
    }
  }
}

.specific-open-hours-info-container,
.specific-open-hours-alert-container {
  .aula-alert {
    position: relative;
    align-items: center;
    display: flex;
    min-height: 30px;
    .alert-text {
      flex: 1;
      margin: auto 8px;
      font-size: 1rem;
    }
    .icon-box-exclamation {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 15px;
        }
      }
    }
  }
}

.specific-open-hours-info-container {
  .alert-text {
    color: $color-primary-darker-guardian;
  }
  .icon-box-exclamation {
    border-color: $color-primary-darker-guardian;

    .circle {
      background-color: $color-white;
      color: $color-primary-darker-guardian;
    }
  }
}

.specific-open-hours-alert-container {
  .alert-text {
    color: $color-alert;
  }
  .aula-alert {
    .icon-box-exclamation {
      background-color: $color-alert;
      border-color: $color-alert;

      .circle {
        background-color: $color-alert;
        color: $color-white;
      }
    }
  }
}

.alert-specific-time {
  color: $color-alert;
}

.register-absence-form {
  .specific-open-hours-alert-container {
    .aula-alert {
      line-height: 20px;
      background: $color-grey-base;
      min-height: 50px;
      border-radius: 5px;
      .alert-text {
        margin: auto 15px;
        color: $color-primary-darker-guardian;
        font-weight: bold;
      }
      .icon-box-exclamation {
        width: 50px;
        min-height: 50px;
        max-height: 100%;
        background-color: $color-grey-dark;
        border-radius: 5px 0 0 5px;
        border-color: $color-grey-dark;

        .circle {
          width: 34px;
          height: 34px;
          background-color: $color-white;
          justify-content: center;
          align-items: center;
          color: $color-primary-darker-guardian;
        }
      }
    }
  }
}
