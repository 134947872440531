

























































































































































































































































































.widget-W0119V0001 {
  .widget-loader {
    font-family: "Lato", "Avenir", Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin: 20px 0
  }

  .widget-loader > .fa {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: both;
    margin-right: 10px
  }

  @keyframes spin {
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(360deg)
    }
  }

  .errors {
    margin-top: 20px
  }

  .errors__error {
    display: block;
    padding: 15px 20px;
    background-color: #fff;
    color: #b50050
  }

  * {
    box-sizing: border-box
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none
  }

  li {
    margin: 0;
    width: 100%
  }

  .widget-title {
    display: block;
    text-align: left;
    font-family: "Lato", "Avenir", Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 30px
  }

  .widget {
    text-align: left
  }

  .widget__item__name {
    display: block;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    color: #5d6c79;
    margin-top: 30px;
    margin-bottom: 10px
  }

  .widgetlist {
    text-align: left
  }

  .widgetlist__item {
    position: relative;
    padding: 15px 20px;
    background-color: #fff;
    margin-bottom: 5px;
    cursor: pointer;
    outline: 0
  }

  .widgetlist__item:focus {
    background-color: rgba(255, 255, 255, .4)
  }

  .widgetlist__item__title {
    font-size: 16px;
    font-weight: 700
  }

  .widgetlist__item__subtitle {
    font-size: 11px;
    font-weight: 700;
    color: #6a7681;
    text-transform: capitalize
  }

  .widgetlist__item__subtitle::first-letter {
    text-transform: uppercase
  }

  .widgetlist__showall {
    display: block;
    padding: 0;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    outline: none
  }

  .widgetlist__showall:focus {
    opacity: .5
  }

  .title {
    background: #233f58;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 10px;
    margin: 0
  }

  .notification {
    position: relative;
    background: #fff;
    padding: 10px;
    transition: transform .15s ease-out
  }

  .notification + .notification {
    margin-top: 8px
  }

  .notification__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 4px
  }

  .notification__title {
    font-size: 14px;
    font-weight: 700;
    color: #2c3e50;
    line-height: 17px;
    margin: 0
  }

  .notification__subtitle {
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 4px;
    color: #2c3e50
  }

  .notification__bubble {
    content: "";
    width: 14px;
    height: 14px;
    display: inline-block;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi42OTkwMiAxLjc1MDM1QzcuNzA4OTggMS43NDAxMSA4LjcwODUzIDEuOTU1MDMgOS42MjUwMiAyLjM3OTQ3QzEwLjQ2NDIgMi43NTUyNiAxMS4xOTM2IDMuMzM4OTIgMTEuNzQ0MyA0LjA3NTIyQzEyLjI1MTYgNC43NDg5OSAxMi41MjUzIDUuNTY5ODMgMTIuNTIzOSA2LjQxMzIyQzEyLjUyNTEgNy4yNTYzMiAxMi4yNTE0IDguMDc2ODIgMTEuNzQ0MyA4Ljc1MDM1QzExLjE5MzYgOS40ODY2NiAxMC40NjQyIDEwLjA3MDMgOS42MjUwMiAxMC40NDYxQzguNzA4NDYgMTAuODcwMiA3LjcwODkxIDExLjA4NDkgNi42OTkwMiAxMS4wNzQzQzYuMDA2MTMgMTEuMDc2OSA1LjMxNjExIDEwLjk4NTEgNC42NDgwMiAxMC44MDEzQzQuMjQ3MTQgMTEuMTI5NSAzLjgwNjA5IDExLjQwNTMgMy4zMzU1MiAxMS42MjIxQzIuOTQxMDQgMTEuODA4NiAyLjUyODkxIDExLjk1NTEgMi4xMDUyNyAxMi4wNTk2QzEuODA4MzggMTIuMTMzNiAxLjUwNzA1IDEyLjE4ODYgMS4yMDMxNSAxMi4yMjQxTDAuODc1MDIxIDEyLjI1MDNDMS4zOTA4NyAxMS43NTczIDEuODI0NzggMTEuMTg1MiAyLjE2MDQgMTAuNTU1NUMyLjMyMzUyIDEwLjI2OTUgMi40MjU3IDkuOTUyNzcgMi40NjA1MiA5LjYyNTM1QzEuOTc5NDkgOS4yMjAwNyAxLjU4NDQgOC43MjI2NCAxLjI5ODUyIDguMTYyMzVDMS4wMjA4MyA3LjYyNTc3IDAuODc1NjIxIDcuMDMwNTIgMC44NzUwMjEgNi40MjYzNUMwLjg3MjIzNSA1LjU3ODcgMS4xNDU5NSA0Ljc1MzI3IDEuNjU0NjUgNC4wNzUyMkMyLjIwNTQ0IDMuMzM5MjQgMi45MzQ4NCAyLjc1NTg5IDMuNzczOSAyLjM4MDM1QzQuNjkwMDUgMS45NTU3MyA1LjY4OTI5IDEuNzQwNTIgNi42OTkwMiAxLjc1MDM1WiIgZmlsbD0iI0RCRTNFOSIvPjwvc3ZnPg==");
    vertical-align: bottom
  }

  .notification__date {
    color: rgba(44, 62, 80, .5);
    font-size: 12px;
    line-height: 14px
  }

  .notification__link {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: #f5836d;
    text-decoration: none;
    display: block
  }

  .notification__link:hover {
    text-decoration: underline
  }

  .notification__link::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
  }

  .notification:hover {
    transform: translate3d(0, 0, 0) scale(1.015)
  }

  .links {
    display: flex;
    justify-content: flex-start;
    padding: 14px 0;
    gap: 10px
  }

  .links > li {
    font-size: 12.6px;
    display: block;
    width: auto;
    color: rgba(0, 0, 0, .5)
  }

  .links a {
    color: #f5836d;
    font-weight: bold;
    text-decoration: underline
  }

  .links a:hover {
    text-decoration: unset;
    color: #e26b54
  }

  .empty-state {
    padding: 20px 10px;
    text-align: center
  }

  .empty-state__title {
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    color: rgba(44, 62, 80, .8);
    margin: 0
  }

  .empty-state__body {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: rgba(44, 62, 80, .8);
    margin: 0
  }
}
