





































































































































.widget-W0102V0001 {
h2 {
  margin: 0;
  font-size: 1.125rem;
}
h3 {
  margin: 2rem 0 0;
  font-size: 1rem;
}
ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-height: 13.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}
a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
a:not([href]) {
  cursor: default;
}
li {
  display: block;
}
li > a {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  font-size: 0.75rem;
}
li > a[href]:hover {
  background: rgba(0, 0, 0, 0.05);
}
li > a > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
h2 > a:hover {
  text-decoration: underline;
  margin: 0;
}
img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
button {
  margin-top: 0.5rem;
}
.error {
  font-size: 12px;
  font-style: italic;
}
}
