







































































































.timeslot-response-variables {
  --display: flex;
  --background-color: var(--color-white);
  --header-min-width: 150px;
}
.timeslot-response {
  display: var(--display);
  &[data-layout='multiple'] {
    --display: block;
    --background-color: var(--color-grey-light);
    --header-min-width: unset;
  }

  .timeslot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color);
    padding: 10px;
    font-weight: bold;
    text-transform: capitalize;
    min-width: var(--header-min-width);
  }
}
.slot-title[aria-disabled='true'] {
  color: var(--color-grey);
}
