
























































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.related-profile-avatar {
  --width: 40px;
  --height: 40px;
  --font-size: 12px;
  --background-color: var(--color-white);
  --color: var(--color-primary-dark);
  --border: 2px solid var(--color-primary-dark);
}

.important-date-container {
  background: white;
  width: 100%;
  margin-top: 75px;
  @include breakpoint-lg-down() {
    margin-top: 12px;
  }
  .important-date-header {
    height: 63px;
    display: flex;
    .important-date-icon {
      height: 63px;
      width: 51px;
      text-align: center;
      margin-right: 5px;
      padding-top: 18px;
      .icon-Aula_calendar {
        font-size: 24px;
        color: white;
      }
    }
    .important-date-title {
      flex: 1;
      height: 63px;
      background-color: $color-grey-base;
      text-indent: 20px;
      text-transform: uppercase;
      font-size: 14px;
      padding-top: 20px;
      .theme-employee & {
        color: $color-primary-darker-employee;
      }
      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }
      .theme-child & {
        color: $color-primary-darker-child;
      }
      .theme-admin & {
        color: $color-primary-darker-admin;
      }
    }
  }
  .no-important-dates {
    margin-top: 5px;
  }
  .important-date-footer {
    clear: both;
  }
  .important-date-body {
    .important-date-row {
      padding: 10px 0;
      margin: 0 auto;
      .important-date-col-detail {
        padding: 0;
        float: left;
        max-width: calc(100% - 115px);
        width: 100%;
        margin-bottom: 10px;
        .from-to {
          font-size: 12px;
        }
      }
      .important-date-col-month {
        text-align: center;
        font-size: 13px;
        padding: 2px;
        max-width: 52px;
        min-width: 52px;
        float: left;
        margin-right: 15px;
        .date {
          font-weight: bold;
        }
        .month {
          font-size: 11px;
          text-transform: uppercase;
        }
      }
      .important-date-col-child {
        float: right;
        max-width: 45px;
        width: 45px;
        .user-img {
          width: 30px;
          border-radius: 30px;
          margin-top: 5px;
        }
        /deep/ {
          .user-icon {
            border: #ffffff 0.5px solid;
            height: 36px;
            width: 36px;
            .user-img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
  &.mobile {
    width: 100%;
  }
}

//Theme styling
.theme-guardian {
  .important-date-icon {
    background: $color-primary-darker-guardian;
  }
}

.theme-employee {
  .important-date-icon {
    background: $color-primary-darker-employee;
  }
}

.theme-child {
  .important-date-icon {
    background: $color-primary-darker-child;
  }
}

.theme-admin {
  .important-date-icon {
    background: $color-primary-darker-admin;
  }
}
