














































































































































































































































































































































































































































































.widget-W0136V0001 {
  .aula-documentsCommonList-container .table .table-row.body .table-cell {
    border-width: 0 0 2px 0;
  }

  .aula-documentsCommonList-container .table .table-row.body .table-cell:has(a) {
    padding: 0px;
  }

  .aula-documentsCommonList-container .table .table-row.body .table-cell a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px;
    user-select: none;
    box-shadow: none;
  }

  .fa {
    line-height: unset;
  }

  b {
    font-weight: 900;
  }

  a {
    color: rgb(25, 66, 95);
  }

  .nct-widget-container {
    background-color: white;
  }

  .nct-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
    background-color: white;
    cursor: pointer;
    user-select: none;
    padding: 14px;
    padding-left: 7px;
  }

  .nct-header i {
    font-weight: 400;
    font-size: 6px;
    height: 6px;
    margin-right: 10px;
    transition: transform 0.15s ease-out;
  }

  .nct-header i.fa {
    font-weight: 300;
    font-size: 24px;
    transition: transform 0.15s ease-out;
  }

  .nct-header.active i:not(.fa) {
    transform: rotate(-180deg);
  }

  .nct-list {
    transition: max-height 0.15s ease-out;
    max-height: 0;
    overflow: hidden;
  }

  .nct-badge {
    border-radius: 100%;
    background-color: rgb(181, 0, 80);
    color: white;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 0.6rem;
    padding-top: 1px;
    display: inline-block;
    margin-left: 10px;
  }

  .nct-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #18638f;
    color: white;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 1rem;
  }

  .nct-avatar span {
    margin-top: -1px;
  }

  .nct-new-document-mark {
    color: #b50050;
    margin-left: 10px;
    font-size: 0.75rem;
  }

  .nct-button-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 270px;
    text-align: center;
    background-color: #e9eef1;
  }

  .nct-button-container > div {
    padding: 20px;
    font-size: 18px;
    margin-top: -4px;
  }

  .nct-button-container > div:first-child {
    margin-top: 30px;
  }

  .nct-button-container > button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background-color: #347da7;
  }

  .nct-button-container > button > span {
    margin-left: 10px;
  }

  .nct-file-icon {
    font-size: 3.5em;
    opacity: 0.2;
  }

  .nct-ml-10 {
    margin-left: 10px;
  }

  .nct-group-name {
    width: 100%;
    padding: 14px;
    padding-left: 18px;
  }

  .nct-icon-container {
    width: 40px;
    text-align: center;
  }

  .nct-icon-container i {
    font-size: 1.5em;
  }

  .nct-document-name,
  .nct-document-name + div {
    width: 30%;
  }

  .nct-error {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .nct-loader {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 48px;
    width: 48px;
  }

  .nct-loader::after,
  .nct-loader::before {
    content: "";
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid;
    border-color: black;
    border-color: var(--color-primary-core);
    position: absolute;
    left: 0;
    top: 0;
    animation: nct-animloader 2s linear infinite;
    opacity: 0.1;
  }

  .nct-loader::after {
    animation-delay: 1s;
  }

  @keyframes nct-animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
