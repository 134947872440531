








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/posts/_posts.scss';

#aula-posts {
  > * {
    max-width: 60rem;
    margin: auto;
  }
  .posts-header {
    --dropdown-select-padding: 0;
  }
}
.primary.cancel-link {
  background: $color-primary-light;
  color: $color-white;
}

.hide-tablet-up {
  .post-item-area {
    overflow-x: hidden;
  }
}

img.menu-icon {
  width: 28px;
  margin-left: -4px;
  margin-top: -4px;
}

.post-item-area {
  @include breakpoint-lg-down() {
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 200px;
  }
}
.config-sections {
  .dropdown-select {
    /deep/ .dropdown-toggle {
      z-index: 1003;
    }
    /deep/ .dropdown-menu-right {
      z-index: 1002;
    }
  }
}
