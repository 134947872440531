





















































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.birthday-title {
  display: none;
  @include breakpoint-sm-up() {
    display: block;
    text-transform: uppercase;
  }
}
.quick-access-item {
  margin-top: 10px;
  height: auto !important;
  min-height: 70px;
  line-height: 1.3 !important;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  background: $color-white;
  display: flex;
  align-content: center;
  white-space: normal !important;
  .text {
    margin: auto 25px;
    @include breakpoint-lg() {
      margin: auto 0;
    }
  }
  &:hover {
    background: $color-grey-light;
  }
}
