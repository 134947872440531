

























































































.response-collapse-button {
  display: flex;
  align-items: center;
  width: fit-content;
}
.invitee-response {
  margin-left: 20px;
}
i.icon-Aula_down-arrow {
  font-size: 6px;
  display: inline-block;
  transform: rotate(0);
  transition: transform 200ms linear;

  &.open {
    transform: rotate(180deg);
  }
}
.number-of-participants {
  width: 100px;
  text-align: center;
}
.participant {
  display: flex;
  margin-right: 20px;
  &.information {
    border-bottom: 1px solid var(--color-grey-dark);
    padding: 10px 0;
    .participant-name {
      flex: 1;
    }
  }
  &.name {
    padding: 3px 0;
  }
}
