
































































.widget-W0013V0002 {
  * {
    background-color: #fff;
  }

  ul {
    margin: 0;
    padding: 12px;
  }

  li {
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (max-width: 1149px) {
    .links-a-employee {
      color: #222350 !important;
      font-size: 15px !important;
      line-height: 1.5 !important;
    }

    .links-a-employee:hover {
      color: #007a8d !important;
      text-decoration: none !important;
    }

    .links-a-employee:focus {
      box-shadow: none !important;
      outline-color: #fff !important;
    }

    .links-a-other {
      color: #222350 !important;
      font-size: 15px !important;
      line-height: 1.5 !important;
    }

    .links-a-other:hover {
      color: #18638f !important;
      text-decoration: none !important;
    }

    .links-a-other:focus {
      box-shadow: none !important;
      outline-color: #fff !important;
    }

    .institution-title {
      margin-top: 8px;
      text-transform: uppercase;
      font-size: 15px;
      display: inline-block;
      white-space: normal;
    }
  }

  @media only screen and (min-width: 1150px) {
    .links-a-employee {
      color: #222350 !important;
      font-size: 16px !important;
      line-height: 1.5 !important;
    }

    .links-a-employee:hover {
      color: #007a8d !important;
      text-decoration: none !important;
    }

    .links-a-employee:focus {
      box-shadow: none !important;
      outline-color: #fff !important;
    }

    .links-a-other {
      color: #222350 !important;
      font-size: 16px !important;
      line-height: 1.5 !important;
    }

    .links-a-other:hover {
      color: #18638f !important;
      text-decoration: none !important;
    }

    .links-a-other:focus {
      box-shadow: none !important;
      outline-color: #fff !important;
    }

    .institution-title {
      margin-top: 8px;
      text-transform: uppercase;
      font-size: 16px;
      display: inline-block;
      white-space: normal;
    }
  }

  .first .institution-title {
    margin-top: 2px !important;
  }

  .links-title-employee {
    font-family: Lato, sans-serif;
    background-color: #007a8d;
    font-size: 18px;
    padding: 12px !important;
    margin-bottom: 0 !important;
    color: #fff;
    display: block;
    text-transform: uppercase;
  }

  .links-title-other {
    font-family: Lato, sans-serif;
    background-color: #18638f;
    font-size: 18px;
    padding: 12px !important;
    margin-bottom: 0 !important;
    color: #fff;
    display: block;
    text-transform: uppercase;
  }
}
