




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '~@fullcalendar/daygrid/main.css';

.calendar-overlay {
  min-height: 500px;
  .calendar-spinner {
    margin-top: 150px;
  }
}
.calendar-event-form {
  padding-bottom: 50px;
}

.drawer-edit-button {
  width: auto;
  height: 30px;
  @include breakpoint-lg() {
    min-width: 110px;
  }
  &.read-only-mode {
    color: $color-white;
    height: 22px;
    margin-right: 65px;
    margin-top: -5px;
    @include breakpoint-sm-down() {
      margin-right: 0px;
      color: $color-darkblue;
    }
  }
}

.no-notification {
  position: relative;
  top: 15px;
  padding: 0 20px;
  margin-bottom: 30px;
}
.loading {
  margin-top: 50px;
  margin-left: -15px;
}
.alert-badge {
  border-radius: 10px;
  background-color: $color-alert;
  color: $color-white;
  height: 20px;
  min-width: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 75%;
  position: relative;
  top: -2px;
  padding: 0 5px;
  margin-left: 4px;
  display: inline-block;
}

.aula-calendar.disable-mouse-clicks {
  pointer-events: none;
}

.aula-calendar {
  /deep/ .week-view-event-group {
    padding: 2px 5px;
  }

  & /deep/ #calendar {
    @include breakpoint-lg() {
      .fc-axis {
        width: var(--fcTimeWidth);
      }

      .fc-time-grid-container {
        height: 580px;
      }

      .fc-row.fc-widget-header {
        margin-right: var(--fcHeaderMarginRight);
        border-right-width: var(--fcHeaderBorderWidth);
      }
    }

    .fc-view-container .fc-event-container .fc-event.lesson-event {
      background-color: $color-white !important;
      border-top: solid 1px #e1e9ee;
      border-bottom: solid 1px #e1e9ee;
      margin: 0 -1px;

      .week-view-title-event .title {
        font-weight: bold;
      }
    }
    /deep/ .fc-time-grid-container {
      @include breakpoint-lg() {
        height: 580px;
      }
    }
  }
}
.widgets-below {
  display: none;
}

@media only screen and (max-width: 991px) {
  .widgets-below {
    display: block;
  }
}
