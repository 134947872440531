











i.icon-Aula_down-arrow {
  font-size: 5px;
  vertical-align: middle;
}
