






































































































































































































































































































































































































































































































































































.widget-W0116V0001 {
.widget-scroll {
  overflow: scroll;
  overflow-x: clip;
  max-height: 550px;
}

.list-table {
  margin: 10px 2px 2px 2px;
  min-width: 175px;
  overflow: hidden;
}

table {
  width: -webkit-fill-available;
}

.hours {
  padding-left: 10px;
  font-size: 13px;
}

.day-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  background-color: #fff;
  margin-top: 5px;
  overflow: hidden;
  min-width: 175px;
}

.day-group .day-view-time-event {
  background: #d9e3e9;
  padding: 10px;
  border-top: 1px solid #f6f7f8;
  font-size: 13px;
  min-width: 50px;
  max-width: 50px;
  text-align: right;
  display: grid;
}

.widget-header {
  text-transform: uppercase;
  padding: 10px;
  font-size: 13px;
  color: #19425f;
  font-weight: 400;
  background: #d9e3e9;
}

.today {
  background: #19425f;
  color: #fff;
}

.day-view-title-event {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  font-size: 13px;
  margin: 0 auto 0 0;
}

.duration-clock {
  display: flex;
  justify-content: center;
}

.weekplan-medium {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.weekplan-narrow {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.title-div {
  background-color: rgb(217, 227, 233);
  border: solid 1px;
  padding: 5px;
  max-width: 180px;
  white-space: pre-line;
  min-width: fit-content;
}

.title-div-position {
  position: absolute;
  margin-top: 7px;
  margin-left: 120px;
}

.title-div-updated {
  position: absolute;
  background-color: rgb(217, 227, 233);
  border: solid 1px;
  padding: 5px;
  max-width: 200px;
}

.new-item {
  padding-bottom: 10px;
}

.mine-timer {
  float: right;
  text-align: right;
  display: flex;
}

.alert-icon {
  text-align: end;
  margin: -10px -7px -5px 0px;
  cursor: pointer;
}

.close-icon {
  text-align: end;
  width: 100%;
  margin: -2px 0px -20px 0px;
  cursor: pointer;
}

body, pre.reset-pre, table td, table th, div {
  font-family: Lato, sans-serif;
}

.weekpicker {
  display: grid;
  grid-template-columns: 100px auto 100px;
  width: 100%;
}

.ist-date {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ist-date-button button {
  width: 55px;
  height: 30px;
  color: white;
}

.date-forward {
  display: flex;
  direction: rtl;
}

.errormessage-chevron {
  margin-left: 10px;
}

.ist-errormessage {
  white-space: pre-line;
  margin-top: 10px;
}

.ist-header {
  display: flex;
  align-items: baseline;
  font-size: 13px;
  padding-top: 10px;
}

.ist-header div {
  -ms-flex: 1;
  /* IE 10 */
  flex: 1;
}

.ist-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #eaeef1;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ist-day {
  display: flex;
}

.ist-day div {
  -ms-flex: 1;
  /* IE 10 */
  flex: 1;
}

.nowrap {
  white-space: nowrap;
}

.nowrap h5 {
  margin-top: 7px;
}

.ist-mine-time-multiple {
  margin: 14px 0px 0px 5px !important;
}

.ist-weekoptions {
  border: solid;
  width: 210px;
  text-align: right;
  padding-right: 10px;
  max-height: 150px;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: white;
  top: 110px;
  margin-left: 155px;
  scroll-margin: 25px;
}

.ist-weekoptions div {
  cursor: pointer;
  margin-right: -10px;
  padding: 5px 10px 0px 5px;
}

.ist-weekoptions div:hover {
  background-color: #d9e3e9;
}

.ist-weekoptions-arrow {
  color: white;
  width: 20px;
  margin-left: 5px;
}

.ist-dtp {
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.mobile-bottom {
  margin-bottom: 50px;
}

.content-bottom {
  align-self: end;
}

.theme-employee .btn-link {
  color: #fff;
}

.absence {
  color: #B50050;
  font-weight: 900;
  padding-left: 10px;
  font-size: 13px;
}

linkbtnmultiple {
  padding-bottom: 10px;
}

linkbtn {
  padding-top: 10px;
}
}
