












































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/_presence.scss';

.come-all-week {
  margin-top: 4px;
}

.vacation-intervals {
  --input-disabled-color: var(--color-help-text);
}

.circle-specific-times {
  align-items: center;
  background: $color-white;
  border-radius: 15px;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-right: 5px;
  width: 20px;
}

.circle-parent {
  padding-right: 0px;
}

.icon-size {
  font-size: 12px;
}
