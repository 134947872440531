




















































@import '../../../shared/assets/scss/core/breakpoints.scss';

@include breakpoint-lg() {
  .timepicker {
    max-width: 100px;
  }
}
