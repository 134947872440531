.aula-presence-employee {
  background-color: $color-grey-light;

  .employee-vacation-request-container {
    @include breakpoint-lg-down() {
      margin-bottom: 100px;
    }

    .drawer-content {
      .el-date-editor.el-input {
        width: 100%;
      }
    }
    .arrow-button {
      width: 30px;
      height: 30px;
      font-size: 1rem;
    }
    .date-label-mobile {
      font-weight: bold;
      width: 80px;
    }
    .presence-institution-icon {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
    }

    .presence-drawer-header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      color: var(--color-darkblue);

      @include breakpoint-sm-up() {
        color: var(--color-white);
        justify-content: flex-start;
      }
    }

    .drawer-edit-button {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--color-darkblue);
      margin-right: 4px;

      @include breakpoint-sm-up() {
        color: var(--color-white);
      }
    }

    .table {
      .table-row {
        &.header {
          display: table-row;
          cursor: default;
          font-weight: 900;
          .table-cell {
            border-color: $color-grey-light;
          }
        }
        .table-cell {
          @include breakpoint-lg() {
            padding-left: 2rem;
            padding-right: 2rem;
            min-width: 200px;
          }
        }
      }
    }
  }

  .presence-choose-week-icon {
    font-family: 'aula';
    font-size: 6px;
    background-color: $color-white;
    padding: 8px;
    border-radius: 4px;

    &:hover {
      .theme-employee & {
        background-color: $color-primary-darker-employee;
      }

      color: $color-white;
    }
  }

  .presence-week-picker {
    position: absolute;
    left: 60px;
    top: 32px;

    @include breakpoint-lg-down() {
      left: unset;
      right: 145px;
      top: 35px;
    }

    .el-input__inner {
      display: none;
    }

    .el-input__prefix {
      display: none;
    }
  }

  .presence-change-week-button {
    padding-left: 0.65em;
    padding-right: 0.65em;
  }

  .presence-main-content {
    @include breakpoint-lg-down() {
      background-color: $color-white;
    }
  }

  .chosen-week-label {
    font-weight: 900;
    min-width: 60px;
    text-transform: uppercase;
  }

  .presence-toolbar {
    position: relative;
    background: $color-grey-base;
    padding: 5px;
    width: 100%;
  }

  .statistic {
    font-weight: 900;
  }

  .presence-header-container {
    position: relative;

    @include breakpoint-lg-down() {
      margin-bottom: 20px;
    }
    .presence-title {
      outline: none;
    }

    .nav {
      flex-wrap: nowrap;
      white-space: nowrap;

      @include breakpoint-lg() {
        flex-wrap: wrap;
        overflow-x: unset;
      }
    }

    .nav-link {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      position: relative;
      cursor: pointer;
      padding-left: 15px;
      padding-right: 15px;

      @include breakpoint-lg-down() {
        font-weight: normal;
        font-size: 15px;
        padding-left: 15px;
        padding-right: 15px;
      }

      &.active::after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 8px 0 8px;
        border-color: hotpink transparent transparent transparent;
        left: calc(50% - 8px);
        bottom: -9px;
        z-index: 1;
      }
    }

    .mobile-page-navbar.navbar .nav-item .nav-link {
      min-width: max-content;
    }
  }

  .box {
    .box-title {
      width: 100%;
      font-size: 15px;
      font-weight: 900;
      background-color: $color-white;
      border-bottom: 1px solid $color-grey-base;
      padding: 25px 5px;
      cursor: pointer;
    }
  }

  .config-sections {
    border-width: 0;
    border-color: hotpink;
    border-bottom-width: 2px;
    border-style: solid;
    margin-bottom: 20px;
    position: relative;

    @include breakpoint-lg-down() {
      border-bottom: 0;
      text-align: right;
      margin-top: 20px;
      margin-bottom: 0;
      margin-right: 10px;
    }

    .dropdown-select {
      /deep/ .dropdown-menu-right {
        top: -2px !important;
        left: auto !important;
        right: 0 !important;
        max-width: inherit !important;
      }

      /deep/ .dropdown-item {
        padding: 0.6rem 20px 0.6rem 28px;
      }

      /deep/ .dropdown-item.active::before {
        top: 12px;
      }

      @media only screen and (min-width: 1280px) {
        display: none;
      }

      .dropdown-toggle .icon {
        font-size: 6px;
        position: relative;
        top: -1px;
        left: 2px;
      }
    }
  }

  .mobile-activity-update {
    position: sticky;
    top: calc(var(--menu-top-bar-height) - 1px);
    z-index: 1;

    .mobile-activity-statistic {
      background-color: var(--color-grey-base);
      padding: 13px 6px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon-Aula_down-arrow {
        font-size: 5px;
        padding: 5px;
      }

      /deep/ .dropdown-menu {
        top: 40px !important;
        left: -20px !important;
        width: 100vw;
        max-width: unset !important;
        padding-top: 8px;
        border-radius: 0;
      }
    }

    .activity-update-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px 6px;
      background-color: var(--color-grey-base);

      .action-button {
        display: flex;
        align-items: center;
      }
    }

    .check-all {
      display: flex;
      justify-content: end;
      background-color: var(--color-grey-base);
      font-weight: bold;
      padding: 13px;
    }
  }

  .activity-container {
    margin-top: 10px;

    @include breakpoint-lg-down() {
      margin-top: 0;
      padding-bottom: 60px;
      width: 100%;
    }

    .dropdown-select {
      .icon-Aula_down-arrow {
        font-size: 8px;
      }
    }

    .activity-list {
      width: 100%;

      .activity-option {
        height: 50px;
      }

      .children-activities {
        width: 100%;

        .closed-day-cell {
          padding: 15px;
          width: 100%;
        }

        @include breakpoint-lg-down() {
          background-color: $color-grey-base;
          padding-bottom: 1px;
        }

        .activity-data {
          display: flex;
          background: $color-white;
          flex-direction: row;
          align-items: stretch;
          width: 100%;
          @include breakpoint-lg-down() {
            margin-top: 5px;
          }
          .cell {
            background-color: $color-white;
            border-bottom: 2px solid $color-grey-light;
            border-right: 2px solid $color-grey-light;

            &.closed-day-cell {
              text-align: center;
              background-color: $color-primary-base-admin;
            }
          }

          .status-icon {
            width: 38px;
            height: 38px;
            background: $color-primary-dark;
            position: relative;
            border-radius: 50%;
            text-align: center;
            font-size: 12px;
            color: $color-white;
            i {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              &.icon-walking-person {
                font-size: 20px;
              }
              &.icon-Ferie_ikon,
              &.icon-Syg_ikon {
                font-size: 16px;
              }
            }
            &.not-present {
              background: $color-attention;
              color: $color-primary-darker-employee;
              font-size: 15px;
            }
            &.sleeping,
            &.physical-placement {
              font-size: 15px;
              background: $color-primary-dark;
              color: $color-white;
            }
            &.present {
              background: $color-primary-dark;
              color: $color-white;
            }
            &.checked-out {
              background: $color-grey-light;
              color: $color-primary-darker-employee;
              border: 2px solid $color-primary-darker-employee;
            }
            &.sick,
            &.reported-absent {
              background: $color-grey-base;
              color: $color-primary-darker-employee;
            }
            &.fieldtrip {
              font-size: 18px;
            }
            &.fieldtrip,
            &.spare-time-activity {
              background: $color-primary-darker-guardian;
              color: $color-white;
            }
            @include breakpoint-lg-down() {
              position: absolute;
              left: 105px;
              top: 15px;
            }
          }
          .user-icon {
            min-width: 84px;
            position: relative;
            padding: 0;
            cursor: pointer;
            border-left: 2px solid $color-white;
            .sick &,
            .reported-absent &,
            .checked-out & {
              border-left: 2px solid $color-grey-light;
            }
            &.not-present-child-icon {
              .initial-name {
                background-color: $color-grey-base;
                color: $color-primary-darker;
              }
              img {
                opacity: 0.6;
              }
            }
            img {
              object-fit: cover;
              width: 80px;
              height: 80px;
            }
            .initial-name {
              width: 80px;
              height: 80px;
              display: flex;
              font-size: 18px;
              justify-content: center;
              align-items: center;
              font-weight: 700;
              background-color: $color-primary-darker-employee;
              color: $color-white;
            }
            .user-details-mobile {
              position: absolute;
              left: 160px;
              top: 15px;
            }

            .push-details-away {
              right: 75px;
            }

            .activities-mobile {
              min-height: 52px;
              padding: 10px;
            }
            &.header {
              border-right: unset;
              border-left: unset;
            }
            .user-status-mobile {
              height: 80px;
              .child-name {
                font-weight: 900;
                .metadata {
                  font-weight: unset;
                }
              }
              .activity-message {
                position: absolute;
                top: 12px;
                right: 45px;
                font-size: 25px;
              }
            }
            @include breakpoint-lg() {
              display: flex;
            }
            @include breakpoint-lg-down() {
              width: 100%;
              background-color: $color-white;
            }
            .mobile-name {
              position: relative;
              top: -8px;
              left: 5px;
            }
            .phone {
              position: absolute;
              border-radius: 0;
              top: 58px;
              width: 22px;
              height: 22px;
              background-color: var(--color-primary-dark);
              @include breakpoint-lg() {
                left: 0;
              }

              display: flex;
              justify-content: center;
              align-items: center;

              i {
                --font-size: 14px;
              }
            }
            &.header.child-icon-col {
              cursor: unset;
            }
            &.empty-cell {
              cursor: unset;
            }
            .user-img {
              object-position: 50% 4%;
            }
          }
          .user-name {
            min-width: 350px;
            padding-top: 28px;
            padding-left: 20px;
            cursor: pointer;
            &.header.child-name-col {
              cursor: unset;
            }
            &.empty-cell {
              cursor: unset;
            }
            @include breakpoint-xxl-down() {
              min-width: 260px;
            }
            @include breakpoint-xl-down() {
              &.child-name-col {
                min-width: 180px;
                padding-right: 20px;
              }
            }
            @include breakpoint-lg-down() {
              width: 100%;
              padding: 0;
            }
            .activity-message {
              margin-left: 10px;
              font-size: 25px;
            }

            .activity-info {
              @include breakpoint-lg-down() {
                float: right;
              }
            }
          }
          .activity-status {
            position: relative;
            min-width: 250px;
            padding: 20px;
            cursor: pointer;

            @include breakpoint-lg-down() {
              padding: 20px 10px;
              min-width: 50px;
            }
            .status-label {
              position: absolute;
              left: 70px;
              top: 28px;
            }
          }
          .grayed-out-cell {
            background-color: $color-grey-light;
            border: 1px solid $color-white;
          }
          .clickable-cell {
            &:focus-visible {
              border: 1px solid $color-darkblue;
            }
          }
          .grayed-out-cell-mobile {
            @include breakpoint-lg-down() {
              background-color: $color-grey-light;
            }
          }
          .child-presence-number,
          .week-activity-status {
            width: 100%;
            padding: 15px 15px;
            display: flex;
            align-items: center;
            @include breakpoint-lg-down() {
              padding: 20px 10px;
            }
            @include breakpoint-xl-down() {
              min-width: 110px;
              padding: 15px 5px;
            }
            &:focus-visible {
              box-shadow: none;
            }
          }
          .week-activity-status {
            .missing-checkout {
              word-spacing: normal;
            }
            @include breakpoint-lg() {
              word-spacing: 0.7rem;
            }
            @include breakpoint-xl-down() {
              word-spacing: unset;
            }
            &:hover:not(.passed-activity) {
              background-color: $color-grey-base;
            }
          }
          .passed-activity {
            color: $color-help-text;
          }
          &.sick,
          &.reported-absent,
          &.checked-out {
            .cell {
              background: $color-grey-light;
              border-bottom: 2px solid $color-white;
              border-right: 2px solid $color-white;
            }
            .initial-name {
              background-color: $color-grey-base;
              color: $color-primary-darker-employee;
            }
            .opacity {
              opacity: 0.4;
            }
          }
          .activity-toggle {
            padding: 15px 20px;
          }
          .activity-name {
            cursor: pointer;
            width: 100%;
            padding: 23px 20px;
            border-right: unset !important;
            @include breakpoint-lg-down() {
              padding: 20px 10px;
            }
          }
          .header.today-highlight {
            background-color: $color-primary-darker-employee;
            color: $color-white;
          }
          .header.activity-weekday {
            cursor: unset;
            width: 100%;
            padding: 20px 20px;
            text-transform: uppercase;
            text-align: center;
            &:last-of-type {
              border-right: unset;
            }
            @include breakpoint-lg-down() {
              padding: 20px 10px;
            }
            @include breakpoint-xl-down() {
              min-width: 110px;
            }
          }
          .activity-checkbox {
            width: 250px;
            padding: 23px 20px 10px 20px !important;

            @include breakpoint-lg-down() {
              position: absolute;
              width: 60px;
              right: 15px;
              top: 0px;
            }

            .custom-control {
              z-index: unset;
            }
          }
          .alert {
            @include breakpoint-lg-down() {
              position: absolute;
              top: 10px;
              right: 10px;
            }
          }
          .icon-aula-down-double-arrow,
          .icon-aula-up-double-arrow {
            cursor: pointer;
            padding: 10px;
            font-size: 10px;
          }
          .icon-Aula_down-arrow,
          .icon-Aula_up-arrow {
            cursor: pointer;
            padding: 10px;
            font-size: 5px;
          }
          .past-activity {
            color: $color-help-text;
            margin-left: 15px;
          }
          .future-activity {
            font-weight: bold;
            margin-left: 15px;
            margin-top: 5px;
            @include breakpoint-lg-down() {
              font-weight: normal;
              margin-bottom: 5px;
            }
            &.active {
              margin-left: 0px;
              &:before {
                content: '•';
                margin-right: 3px;
              }
            }
            &.emphasized {
              color: $color-alert;
            }
          }
          .header {
            background-color: $color-grey-base;
            border-right: 2px solid $color-grey-light;
            padding: 20px;
            font-weight: 900;
            cursor: pointer;
            &.asc::after {
              font-family: 'aula', Lato, serif;
              content: '\e988';
            }
            &.desc::after {
              font-family: 'aula', Lato, serif;
              content: '\e987';
            }
          }
        }
      }
    }
  }

  .presence-toolbar-mobile {
    background-color: $color-grey-light;

    .navbar-nav {
      padding: 13px 15px;
    }

    .presence-toolbar-menu {
      position: fixed;
      top: 0;
      left: 0;
      min-height: 100vh;
      min-width: 100vw;
      // z-index must be higher than #Aula-header
      z-index: $presence-toolbar-mobile-menu-header;
      background-color: $color-grey-base;
      font-size: 14px;
      font-weight: bold;

      .theme-employee & {
        color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }

      .theme-child & {
        color: $color-primary-darker-child;
      }

      .theme-admin & {
        color: $color-primary-darker-admin;
      }

      .menu-header {
        position: relative;
        width: 100%;
        height: 57px;
        top: 0;
        padding: 0;
        background-color: $color-primary-darker;
        font-weight: bold;

        .icon-Aula_arrow_new_item {
          color: $color-white;
          font-weight: 900;
          font-size: 28px;
          display: inline-block;
          line-height: 57px;
          vertical-align: top;
          float: left;
          margin: 0 -48px 0 20px;
          -webkit-transform: rotate(270deg);
          -moz-transform: rotate(270deg);
          -o-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
          transform: rotate(270deg);
        }

        .text {
          color: $color-white;
          line-height: 57px;
          text-align: center;
          font-size: 14px;
        }
      }

      .menu-body {
        @include breakpoint-lg-down() {
          height: calc(100vh - 58px);
          overflow: scroll;
          padding-bottom: 100px;
        }

        .toolbar-title {
          height: 45px;
          width: 100%;
          background-color: $color-grey-base;
          padding: 12px 29px;
          margin-bottom: 0;
          border-bottom: 1px solid $color-white;
          &.custom-checkbox {
            padding-left: 40px;
          }
        }

        .toolbar-item {
          height: 45px;
          width: 100%;
          background-color: $color-grey-lighter;
          padding: 12px 0 12px 49px;
          margin-bottom: 0;
          border-bottom: 1px solid $color-white;
          &.custom-checkbox {
            padding-left: 55px;
          }
        }

        .el-date-editor {
          width: 100%;
        }
      }
    }

    .icon-Aula_back-arrow {
      font-size: 0.8rem;
      line-height: unset;
    }
  }
}

.presence-drawer-container {
  .large-user-photo {
    width: 200px;
    height: 200px;
    @include breakpoint-sm-down() {
      width: 120px;
      height: 120px;
    }

    /deep/.user-icon .shortname {
      font-size: 1.2em;
    }
  }
  .child-name {
    font-size: 1.5rem;
  }
  .separating-line {
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-grey-dark;
  }
  .section-header {
    font-weight: 700;
    font-size: 17px;
    text-transform: uppercase;
  }
}
.presence-drawer-icon {
  font-size: 1.3rem;
}

.presence-distribution-container {
  margin: 0 15px;
  @include breakpoint-lg() {
    margin: 0 30px;
    width: 400px;
  }

  .distribution-data-row {
    text-transform: uppercase;
    padding: 15px 0;
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-grey-dark;
    }
    &.highlighted-interval {
      font-weight: 700;
    }
  }
  .coming-children-label {
    text-transform: uppercase;
    font-weight: 700;
    padding: 20px 0;
    @include breakpoint-lg-down() {
      background-color: $color-grey-base;
      padding: 10px 20px;
      margin: 0 -35px;
    }
  }
}

.filter-sorter .dropdown-toggle {
  .icon-Aula_down-arrow {
    font-size: 5px;
    position: relative;
    top: -1px;
  }
}

/deep/ .user {
  display: block;
  float: left;

  .small span {
    line-height: 50px !important;
  }
}

.institution-mobile {
  font-weight: 900;
  color: $color-primary-darker;
  background-color: $color-grey-dark;
}

.edit-button {
  padding: 15px 56px 14px 56px;
}

.current-week-button,
.refresh-button {
  font-weight: 900;
  font-size: 15px;
  color: $color-primary-dark-employee;
  text-decoration: underline;

  &:hover {
    color: $color-darkblue;
  }
}

.cancel-button {
  padding: 14px 44px;
}

.department-mobile {
  font-weight: 900;
  background-color: $color-grey-base;
}

.filter-sorter {
  /deep/ .dropdown-menu {
    top: 45px !important;
    padding-top: 0;
    left: -6px !important;
    max-width: 200px !important;
    width: 170px !important;
    max-height: 400px;
    overflow: auto;
  }
}

.update-options {
  width: 200px;
  float: right;
}

.toggle-locations {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30px;
  padding-top: 8px;

  i {
    font-size: 7px !important;
  }

  .el-select-dropdown & {
    padding-top: 14px;
  }
}

.el-select-dropdown {
  .el-select-dropdown__item {
    text-align: left;
  }
}

.location-dropdown-item {
  padding-left: 40px;
}

.b-nav-dropdown {
  /deep/ a {
    position: relative;
    z-index: 1003;
    padding: 0.8rem;
    padding-left: 1.1rem !important;
    font-size: 14px;
    text-transform: uppercase;

    span:not(.small-icon) {
      padding-left: 1.2rem;
      margin-right: 0.5rem !important;
    }
  }

  /deep/ .dropdown-menu {
    top: 0 !important;
    left: -10px !important;
    padding-top: 4rem;
    max-width: 180px !important;
    width: 180px;
  }

  /deep/ .dropdown-menu-right {
    z-index: 1002;
  }

  .dropdown-item {
    padding-left: 0.5rem;

    i {
      width: 20px;
      display: inline-block;
      text-align: center;
      font-size: 22px;
      margin-top: -4px;
      float: left;
    }

    a:first-child {
      margin-top: 0.75rem;
      padding-left: 1.35rem !important;
    }
  }

  /deep/ > a {
    padding-top: 13px;
    float: right;
    height: 50px;

    button {
      font-weight: 400;
      text-transform: uppercase;
      color: $color-primary-dark-employee !important;
    }
  }

  /deep/ > div {
    min-width: 13em;
  }

  &.dropdown-select {
    width: 205px;
    text-align: left;
    list-style-type: none;
    padding: 0;

    &.show {
      .icon-Aula_close {
        margin-top: 0 !important;
      }

      .icon-Aula_down-arrow {
        display: none;
      }

      > a {
        padding-top: 0.5rem;
      }
    }

    &:not(.show) {
      .icon-Aula_close {
        display: none;
      }
    }

    span.small-icon {
      float: right;
    }

    .icon-Aula_close {
      margin-left: 15px !important;
      margin-right: 0 !important;
    }

    .icon-Aula_down-arrow {
      margin-bottom: 0 !important;
      margin-top: 12px !important;
      margin-right: 8px !important;
    }
  }

  .nav-link .btn {
    padding-right: 36px;
    padding-left: 15px;
    padding-top: 3px;

    > .small-icon {
      position: absolute;
      right: 12px;
      top: 20px;

      &.icon-Aula_down-arrow {
        top: 9px;
        font-size: 8px;
      }
    }
  }
}

.b-nav-dropdown:not(.show) /deep/ > a {
  border-radius: 5px;

  button {
    color: $color-white !important;
  }

  background-color: $color-primary-darker-employee;
}

.theme-guardian {
  .aula-presence-employee {
    color: $color-primary-darker-guardian;

    .user-name a,
    .user-icon a {
      color: $color-primary-darker-guardian;
    }

    .config-sections {
      border-color: $color-primary-darker-guardian;

      .nav-item {
        color: $color-primary-dark-guardian;

        .active,
        &:hover {
          color: $color-primary-darker-guardian;
        }

        .active::after {
          border-color: $color-primary-darker-guardian transparent transparent transparent;
        }
      }
    }
  }
}

.theme-employee {
  .btn-link {
    &.current-week-button,
    &.refresh-button {
      color: $color-primary-dark-employee;
    }
  }
  .aula-presence-employee {
    color: $color-primary-darker-employee;

    .user-name a,
    .user-icon a {
      color: $color-primary-darker-employee;
    }

    .config-sections {
      border-color: $color-primary-darker-employee;

      .nav-item {
        color: $color-primary-dark-employee;

        .active,
        &:hover {
          color: $color-primary-darker-employee;
        }

        .active::after {
          border-color: $color-primary-darker-employee transparent transparent transparent;
        }
      }
    }
  }
}

.theme-child {
  .aula-presence-employee {
    color: $color-primary-darker-guardian;

    .user-name a,
    .user-icon a {
      color: $color-primary-darker-guardian;
    }

    .config-sections {
      border-color: $color-primary-darker-child;

      .nav-item {
        color: $color-primary-base-child;

        .active,
        &:hover {
          color: $color-primary-darker-child;
        }

        .active::after {
          border-color: $color-primary-darker-child transparent transparent transparent;
        }
      }
    }
  }
}

@media print {
  #groupFilter {
    overflow: hidden;
  }

  .group-filter-search {
    max-width: 60%;
  }

  .edit-button {
    display: none;
  }

  .presence-main-content {
    .col-6 {
      max-width: 49%;
      display: inline-block;
    }
  }
}

.opening-hour-and-closed-day-container {
  @include breakpoint-lg-down() {
    padding-bottom: $menu-link-height;
  }
}

.daily-note {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    text-transform: none;
    font-weight: normal;
    font-size: large;
    margin: 0 12px;
  }
}
