























































































































































































































































































































































































































































































































































































































































.widget-W0014V0001 {
.easyiq-wp-widgetheader
{
  background-color:#17a2b8;
  color:#000;
  font-size:1em;
}
.easyiq-wp-widgetsubheader{
  background-color:#45b7c1;
  color:#FFF;
  font-size:1em;
}

.easyiq-wp-classesDropdown .dropdown-toggle{
  background-color:transparent;
}

.easyiq-wp-classesDropdown{
  background-color:transparent;
}
/*bootstrap overrides*/

.easyiq-wp-classesDropdown /deep/ .dropdown-menu
{
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  font-size:0.9em;
}

/*bootstrap overrides end*/
.easyiq-spMain {
  font-size:0.8em;
}

.easyiq-wp-notificationitem {
  font-size: 0.9em;
  margin: 5px 0;
}

.easyiq-wp-notificationitem [data-notification-read='false'] {
  background-color: aliceblue !important;
}

.easyiq-wp-notificationitem [data-notification-read='true'] {
}

.easyiq-wp-notificationbadge {
  position: absolute;
  top: 0.2em;
  right: -0.4em;
  height: 1.4em;
  font-size: 0.6em;
  width: 1.4em;
}
.easyiq-wp-notificationbrand {
  position: relative;
}
.easyiq-wp-notificationbrand button{
  background:transparent;
  padding:0;
  margin:0;
}
.easyiq-wp-notificationbrand popover-body {
  padding: 0 !important;
}

.easyiq-wp-info {
  font-weight: normal;
  text-align: left;
  border-radius: 4px;
  background-color: rgba(212, 239, 255,1);
  border: 1px solid rgba(212, 239, 255,0.7);
  padding: 5px 35px 0 5px;
  min-height: 80px;
  margin:10px 0;
}
.easyiq-wp-eventContainer-title
{
  z-index:10;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.2em;
}
.easyiq-wp-weekcontainer[data-wptype="8"] {
  background-color: #92b1c6;
  border: 1px solid #81a5bd;
}

.easyiq-wp-weekcontainer[data-wptype="9"] {
  background-color: #d4efff;
  border: 1px solid #bbe6ff;
}

.easyiq-wp-weekcontainer[data-wptype="5"] {
  background-color: #8dbfe0;
  border: 1px solid #79b4db;
}

.easyiq-wp-weekcontainer[data-wptype="2"] {
  background-color: #a5dbff;
  border: 1px solid #8bd1ff;
}

.easyiq-wp-weekcontainer[data-wptype="6"] {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(242, 242, 242, 0.5);
}

.easyiq-wp-eventContainer {
  position:relative;
  width: 100%;
}

.easyiq-wp-eventContainer > h2 {
  text-align: center;
  margin: 0;
}
.easyiq-wp-dayinformationevent {
  font-weight: normal;
  text-align: left;
  border-radius: 4px;
  margin: 10px;
}
.easyiq-wp-dayinformationevent /deep/ p {
  padding: 0.4em;
}
.easyiq-wp-weekcontainer tr > .easyiq-wp-weekday {
  font-size: 0.9em;
  font-weight: bold;
  margin: 0;
  height: 100%;
  text-align: center;
  align-content: space-around;
  align-items: flex-start;
  list-style: none;
  position: relative;
  color: #000;
}

.easyiq-wp-weekcontainer tr > .wp-weekday img {
  opacity: 0.4;
}

.easyiq-wp-weekcontainer tr > .wp-weekday img:hover {
  opacity: 1;
}

.easyiq-wp-weekcontainer tr > .wp-weekday .wp-dayEvents {
  width: 100%;
  height: 100%;
}

.easyiq-wp-weekcontainer {
  width: 100%;
  margin: 0;
  color: black;
  border: 1px solid rgba(182, 182, 182, 0.88);
  position: relative;
  table-layout: fixed;
  height: 60px;
  color: #FFF;
}
.easyiq-wp-dayinformationevent p {
  padding: 0 5px 0 5px;
  margin: 5px 0;
}
.easyiq-wp-weekcontainer tr {
  font-size: 0.9em;
  font-weight: bold;
  margin: 0;
  height: 100%;
  text-align: center;
  align-content: space-around;
  align-items: flex-start;
  list-style: none;
  border-left: 1px solid rgba(182, 182, 182, 0.88);
  position: relative;
}
.easyiq-wp-weekcontainer td
{
  vertical-align:top;
}

.easyiq-wp-weekcontainer[data-showdays="1"] {
  color:#000;
  height: 20px;
}

.easyiq-wp-weekday video, .easyiq-wp-info video {
  width: 100%;
}
.easyiq-wp-dayevent-header
{
  background-color:rgb(98, 130, 152);
  border:1px solid rgba(128, 128, 128,0.6);
}
.easyiq-wp-dayevent {
}

.easyiq-plandetail-skillmethod {
  font-size: 0.8em;
}

.easyiq-plandetail-skillmethod:before {
  content: "\2022";
}
.easyiq-wp-day-plancontainer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  height: 35px;
}
.easyiq-wp-day-plancontainer img {
  opacity: 0.4;
}
.easyiq-wp-day-plancontainer img:hover {
  opacity: 1;
}
.easyiq-wp-day-plancontainer img {
  margin: 0 5px;
  width: 20px;
}
.easyiq-wp-day-plancontainer p {
  font-size: 1.0em;
  margin: 0;
  padding: 5px;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.easyiq-wp-day-plancontainer .easyiq-wp-day-plan-start {
  position: absolute;
  left: 0;
  width: 10px;
  height: 100%;
  background-color: rgba(43, 43, 43, 0.6);
}

.easyiq-wp-day-plancontainer .easyiq-wp-day-plan-title {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;

}
.widget-toplink a {
  color: #FFF;
  text-decoration: underline;
  text-align:right;
}

.easyiq-wp-day-plan-title a{
  color:#FFF;
  text-decoration:underline;
}

.easyiq-wp-day-plancontainer .easyiq-wp-day-plan-courses {
  font-style: italic;
  font-size: 0.8em;
}
.easyiq-wp-day-plancontainer .easyiq-wp-day-plan-end {
  position: absolute;
  right: 0;
  width: 10px;
  height: 100%;
  background-color: rgba(43, 43, 43, 0.6);
}
.easyiq-wp-day-plancontainer-hideStart .easyiq-wp-day-plan-start {
  display: none !important;
}
.easyiq-wp-day-plancontainer-hideEnd .easyiq-wp-day-plan-end {
  display: none !important;
}

.easyiq-wp-dayassignmentevent {
  width: 85%;
  border: 1px solid rgba(124, 124, 124, 0.51);
  border-radius: 4px;
  margin: 10px;
}

.easyiq-wp-dayassignmentevent p {
  padding: 0 5px 0 5px;
  margin: 5px 0;
}

.easyiq-wp-dayassignmentevent .easyiq-wp-dayassignmentevent-description {
  font-weight: normal;
  text-align: left;
  padding: 5px;
}

.easyiq-wp-dayassignmentevent .easyiq-wp-dayassignmentevent-course {
  font-weight: normal;
  font-size: 0.8em;
  text-align: left;
  font-style: italic;
}

.easyiq-wp-dayassignmentevent .easyiq-wp-dayassignmentevent-title {
  text-align: left;
  font-weight: bold;
}

.easyiq-wp-dayassignmentevent div[data-icontitle] {
  width: 100%;
}
.easyiq-wp-weekcontainer-datecolumn {
  border-left: 1px solid rgba(255, 255, 255, 1.00);
  text-align: center;
  font-size: 0.95em;
  text-transform: capitalize;
  color: rgba(0,0,0,0.7);
  height:1.5em;
}
.easyiq-wp-weekinfocontainerlinks {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  display: flex;
  margin-left:auto;
}
.easyiq-wp-weekinfocontainer {
  display: flex;
  background-color: #D9DFE3;
  color: #000;
  padding:0.4em;
}

.easyiq-wp-weekinfocontainerlinks div {
  margin: 0 2px;
}

.easyiq-wp-currentweekcontainer {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
}

.easyiq-wp-todayweekcontainer {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  margin-right: 10px;
}
.easyiq-wp-weekinfocontaineruser {
  font-size: 0.8em;
  justify-content: center;
  align-self: center;
  color: #12798c;
}

* div[role='link']
{
  cursor:pointer;
  color: #000;
}
* div[role='link']:hover
{
  text-decoration:underline;
}

.easyiq-wp-arrowcirle{
  transform:scale(1.3);
  margin:2px;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.lds-css {
  width: 240px;
  height: 240px;
  margin: auto;
  transform: scale(0.7);
}

.lds-ring {
  height: 100%;
  width: 100%;
}

.lds-ring > div {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 10px solid #fff;
  border-color: #fff transparent transparent transparent;
  animation: lds-ring 1.5s cubic-bezier(0.5,0,0.5,1) infinite;
}

.lds-ring > div:nth-child(1) {
  border-color: rgba(0, 122, 141, 0.25);
  animation: none;
}

.lds-ring > div:nth-child(3) {
  animation-delay: .19s;
}

.lds-ring > div:nth-child(4) {
  animation-delay: .38s;
}

.lds-ring > div:nth-child(5) {
  animation-delay: .57s;
}
}
