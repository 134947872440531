






































































































































































































.row {
  --row-padding-top: 0;
  --row-padding-bottom: 0;
  --row-padding-start: 0;
  --row-padding-end: 0;
}

.mobile-day-display {
  min-width: 100px;
}

.column {
  background-color: var(--color-grey-base);

  &:first-child {
    margin-right: 1px;
  }

  &:last-child {
    margin-left: 1px;
  }
}

.checkbox-text-disabled {
  color: var(--color-dark-grey);
  font-weight: bold;
}

.separator {
  padding-top: 10px;
  padding-bottom: 10px;
}
