



























































































































































































































































































































































































































































































































































































































































































































































































@import '../assets/scss/core/_variables.scss';
@import '../assets/scss/core/_breakpoints.scss';
@import '../assets/scss/components/alerts/_alerts.scss';

.no-data {
  background-color: var(--color-grey-base);
}

.mw-80 {
  max-width: 80%;
  margin: 20px;
  @include breakpoint-lg-down() {
    max-width: 100%;
  }
}

.slide-only-enter-active,
.slide-only-leave-active {
  transition: transform 1s ease;
}

.slide-only-enter,
.slide-only-leave-to {
  transform: translateX(103%);
}
