










































































































































































.row {
  --row-padding-top: 0;
  --row-padding-bottom: 0;
  --row-padding-start: 0;
  --row-padding-end: 0;
  background-color: var(--background-color, var(--color-grey-base));

  .timepicker-group {
    margin: 0 5px;

    label.timepicker-label[for] {
      margin-top: 0;
      font-weight: normal;
      text-transform: none;
    }
  }
}
