






































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.aula-widget {
  /deep/ .modal-dialog {
    @include breakpoint-lg() {
      top: 65px;
    }
    @include breakpoint-xl() {
      top: 100px;
    }
  }

  .notice-boards & {
    margin: 0;
  }

  /deep/ {
    h1,
    h2 {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  .restricted-groups {
    font-style: italic;
  }

  .widgetBox {
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      padding-bottom: 0.25rem;
      color: var(--color-primary-darker);

      .widget-name {
        font-size: 1rem;
        font-weight: bold;
        flex-grow: 1;
      }

      .widget-supplier {
        font-size: 0.7rem;
      }
    }

    .id {
      display: none;
    }

    .content {
      padding: 5px;
      font-size: 15px;
      border: solid 1px var(--color-primary-light);

      /deep/ .container-fluid {
        padding: 0 15px;
      }

      /deep/ input::-webkit-outer-spin-button,
      /deep/ input::-webkit-inner-spin-button,
      /deep/ input::-webkit-calendar-picker-indicator {
        display: block;
      }
    }

    .iframe-content {
      width: 100%;
      padding: 5px;
      border: solid 1px var(--color-primary-light);
    }
  }
}
