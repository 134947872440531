















































































































































































.send-reminder {
  display: flex;
  align-items: center;
}
.send-reminder-button {
  --btn-link-color: var(--color-primary-dark);

  display: flex;
  align-items: center;
  .icon {
    --font-size: 1.5rem;
  }
}
