











































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.show-more-pending-answers {
  margin-right: 20px;
}

.send-reminder-container {
  display: inline-flex;
  height: 1rem;
  vertical-align: sub;
}

.filter-responses {
  padding-top: 10px;
  padding-bottom: 20px;
  display: inline-flex;
  @include breakpoint-lg-down() {
    background-color: $color-grey-dark;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 20px;
    margin-bottom: 5px;
  }
  .filtering-department-group {
    display: flex;
    align-items: center;
    @include breakpoint-lg-down() {
      margin-left: 20px;
    }
    /deep/ .dropdown-menu {
      width: 250px;
      max-width: unset !important;
      @include breakpoint-lg-down() {
        width: 300px;
      }
      .dropdown-item a {
        word-break: break-all;
      }
      .department {
        background-color: $color-grey-base !important;
      }
      .group {
        padding-left: 45px;
      }
    }

    .icon-Aula_down-arrow {
      font-size: 5px;
      margin-top: 3px;
      margin-left: 4px;
    }
  }
}
/deep/.day-response-status {
  .modal-aula {
    .modal-body {
      padding: 0 !important;
    }
  }
}
a.disabled {
  pointer-events: none;
  color: var(--color-grey);
}
