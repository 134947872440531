









































































































































































































































































































































.widget-W0114V0001 {
div.icon-rotator {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD5UlEQVRo3u2ZT4gbdRTHv++XJUzwT6HuoUrB0nhQKR6kerG9eCgL1l3FdT1ZpLB4kL1Edmf29rtlJpsuSPEgQvdiD6ZQdS1ioTf/gFUQ24KSS3qQIloKVXGSyWa+Hjq7JNNkMzOZcT3M9zZv5v3m98n7vfd+8wuQK1euXLlyJZekPaDW+mHDMI6TPCIijwPYB2ALwB2SvwH4rtPp/KC1/vt/B6K1njIMYx7AaQAvAiiMcekC+ALARrvd/lxr7e85iOM48yQdAIeT+JO8JiIrlmVdnmQeU0kd19fX93ued47k3CQTEJEnRaQ0yRhAwohUq9UnROQyRkdhC8BVki0R+V1EPJLTAJ4C8CwAI3jOE5E3TNP89D8HCSC+AnBgyO2fAKwXi8VLlUrlzjB/rfWDhmHMAlgSEScNiNggwXL6HvdH4raIVFzXPZ9G4iZRrBzxPO9cGEJEbiilZpeXl1t7AbAtFfVBx3HmAQwktojccF33hawgHMc5mCqI1noqKLH9uq2UmtVa/5kFRLVanSHZtG37ndRAgmYXXlKVrCJRrVZnROQTACUAZ6PARF1ap/svSF5zXfd8RhAnAojtEi0BzNJEILZt78O9bceORORMFtUpiMRnfRA7rwTw3m6RiRKRYxjcO20Vi8VLGUH0RyKs7cgMhRkLQvJIyHR1VLPLEGIszFgQpdShkOnmHkHsCjMWxPf9h0KmWylCnIgJEYZZ6jcAAGzbXgTwAZJtJD2l1GsrKyup5I5t22cAVPpMH1mW9eZuPjsRsSzrQwBLABjzvW2Sc2lBBHqs/4Lk2KY7sLQsy3o/Jkyb5Kurq6tfpggBAIdC1zdjgcSEyQSiXq9PA3guZL4eGyQiTFaRQK/XexmDfaurlPomEUgfzNtDYDyl1OtZQDQajQIGkxwArpim+VdikAAmXACySOwdtVqtU+EGTHIjiu/YPtK3zNyslhMA1Gq1Msl6yNwsl8sXo/hH7hmO4xw0TfPXLCCCjem3AJ4emJzInGmam6mCZKVarVb2fX9zCMQF0zQXoo6T+FxrUjUajUKr1Trl+34dwP7Q7SbJxTjjpRYRx3FeIWmJyFkAm6MqTb1en+52uyeVUu8O2VkDwK1CoXAs7tdnKiABxMcAioGpLSI/kvxZRP4gWRSRAyQPAziK0WfDzUKhMJPkEzqNs98wRCKJyAWSi5Zl3U3iP3GO+L7/D4BfROSZhEM0RWQ5anUa+UNMCgIAWmtVKpVOknwLwEsYH50ugCskN8rl8sWFhYXepHNIvfyura09QPJor9d7XkQeBfAIyQKAu7i3i73e6XS+TvuPnly5cuXKlWsS/QvhKq1sLYByvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMTozNjo1NSswMDowMPTXOyYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTE6MzY6NTUrMDA6MDCFioOaAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 25px;
  display: inline-block;
}


div.icon-spf {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACp0lEQVRIS9WVS2xNURSGvz8GiBh4jbxiIATxmiDShDAxlSAeYUAIqo1OdODRYsCkQpUQBkQJElMTDYmImlBSQgzEa+Q1EMFAlvw3+9bp6Tm3nVrJzbnn7L3Wv/Za//q3GIJFxHBgDrAgbX8C9Er6PZi7am2IiLVAYwo8Mrf3J2Cgk5JulMUpBYiIS8Dm5PgeeAm8Su8zgJnA5PR+WdKWIpBCgIj4DIwDvgHNks4XOUfEduAYMAb4Iml8ft8AgIi4AmwErkrys88iotsvkhbnvncCG4BOSZuya/0AImK9AwNdklbms4mISABFid0BVhhI0rWqb9/GiJgL3AXGAtOBP66zpNvVzUUAEbEq9WcY8Br4CiyX9KySTMZ5N3AaaJXUEhGngD3ATaBJ0ocsQERMAtqANUC7pIaIaAEOAfWSOvIAF4CtgGt4LwWvB0alJE4Aewv+/0iJtQPLAPfwoqRteYDHie/zqseLiPlmEbCuhOfXzSJJPV5PZX7q+ZC0MA9Qq4HOzEf30+YTupR+9rN8n7I9MAUXAbMlvUgZeaCaAPO9yDwfbZIqAxgRs4DnwKMqlbMA1aZaHh4AO5NMjE6RjwL7C/5/t1wAZ4GlgGWj0vR8iZzlOaBFUmtEeEL3AWfSt085Fk3wd2AXcFxSc4ZFO6rTnz2BlfJ+Yk0dYP2Zlq1zyRy4L2+SLtnfrKqTZCH8Nwephi7BEddRkuW5ZgOzixHR6/4BByS5nBUrGvmHgLWmQ5LnoM/KpCIiPKAe1G5JS7I+ZWr6FpgCfAQaJN0qolBErAZMjonAO0lT8/tq3QdmgdlhM5B1xneCzXeB9cqBbY2SDDTABrvRrI6HAU/liJz3L8DTf1BSV1Hwwh6UlMLBLRv+2SwNPZIMUtNqnmAw56Gs//8AfwEguxIoVgPDyAAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-trafficLight {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAhklEQVRIx+2RwQ2DMBAE16nDPKB0P1AqgAIgtaCkieHjSImF4QQ8b14n3Wl3dSs5VwBeHDPtaYQDAyxBQghVnceG6OcbrVgNkqKkJs9/QTJvy1uelVfEn5umctNbDDpgOWGwAJ3lpQJaIBUCAxCz+FjsEtB6yV6yl+wl31VywWzQnww3Tp0VnZpacqu5CHUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTAtMDlUMTI6MTA6MTcrMDA6MDCBUOaHAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEwLTA5VDEyOjEwOjE3KzAwOjAw8A1eOwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

i {
  font-size: 14px;
}

i.chevron {
  margin-right: 10px;
  height: 14px;
}

span.icon-alarm-red {
  padding: 9px;
  color: #e43351;
}

span.icon-alarm-yellow {
  padding: 9px;
  color: #f8b100;
}

span.icon-alarm-green {
  padding: 9px;
  color: #96be2c;
}

i.icon-bell-off {
  padding: 9px;
  color: #e43351;
  margin-left: 5px;
}

.header-link {
  cursor: pointer;
  margin-left: 5px;
  line-height: 35px;
}

.main-section {
  border: 2px solid white;
  background-color: #FAFAFA;
}


.wrapper {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.icon-color-red {
  background-color: #e43351
}

.icon-color-green {
  background-color: #96be2c
}

.icon-color-yellow {
  background-color: #f8b100
}


.quick-access-item {
  position: relative;
}

.theme-circle {
  width: 18px;
  height: 18px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  margin-right: 5px;
  margin-top: -1px;
  text-align: center;
  line-height: 18px;
  border-radius: 10px;
  background-color: #873C79;
  margin-left: 10px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}


.infoba-header {
  color: #fff;
  padding: 4px;
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #888;
  position: relative;
  margin-top: 20px
}

.infoba-header:first-child {
  margin-top: 0
}

.infoba-header i {
  /*position: absolute;*/
  top: 2px;
  line-height: 35px;
  font-size: 20px !important
}

.infoba-header span {
  position: absolute;
  left: 34px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  top: 5px
}


.icon-color-green {
  background-color: #96be2c
}

.icon-color-grey {
  background-color: #ccc
}

.icon-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.quick-access-item {
  position: relative
}

.infoba-quick-access-item {
  padding: 20px 35px 20px 35px;
  cursor: auto;
  height: auto
}

.infoba-gray {
  color: #888
}

.infoba-link {
  color: #888 !important;
  cursor: pointer
}

.slide-enter-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in
}

.slide-leave-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, .5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, .5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, .5, 1);
  transition-timing-function: cubic-bezier(0, 1, .5, 1)
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0
}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}

.rotate.up {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.rotate.right {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.infoba-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}


.infoba-accordeon {
  padding-left: 5px
}

.infoba-accordeon-header {
  padding-left: 24px;
  background-color: #FFFFFF;
  line-height: 46px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.infoba-accordeon-row {
  background-color: #FFFFFF;
  padding-left: 5px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.infoba-section {
  padding-left: 24px;
  background-color: #FFFFFF;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
}

.infoba-accordeon-row:last-child {
  padding-bottom: 20px;
}

.infoba-row {
  padding-left: 5px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc
}


.infoba-logo {
  height: 35px
}
}
