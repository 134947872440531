#aula-posts {
  background-color: $color-grey-light;

  .notice-boards & {
    margin: -10px;
    padding: 10px;
    background: $color-white;
    overflow-y: auto;
    height: 100%;
  }

  .posts-header {
    display: flex;

    .posts-title {
      flex-grow: 1;
      outline: none;
    }
  }

  .posts-create {
    padding: 14px 20px;
    text-transform: uppercase;
  }

  .posts-sort-wrapper {
    display: block;

    @include breakpoint-lg() {
      display: none;
    }
  }

  .nav {
    flex-wrap: nowrap;
    white-space: nowrap;

    @include breakpoint-lg() {
      flex-wrap: wrap;
      overflow-x: none;
    }
  }

  .nav-link {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    position: relative;
    cursor: pointer;

    @include breakpoint-sm-down() {
      font-weight: normal;
      font-size: 15px;
    }

    .icon-Aula_check {
      position: absolute;
      left: 12px;
      top: 11px;
      display: none;
    }

    &.active::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: transparent;
      left: calc(50% - 5px);
      bottom: -6px;
      z-index: 1;
    }
  }

  .post-header-container {
    position: relative;

    @include breakpoint-lg-down() {
      margin-bottom: 20px;
    }
  }

  .config-sections {
    margin-bottom: 20px;
    position: relative;

    @include breakpoint-lg-down() {
      border-bottom: 0;
      text-align: right;
      margin-top: 20px;
      margin-bottom: 0;
      margin-right: 10px;
    }

    .dropdown-select {
      /deep/ .dropdown-menu-right {
        top: -2px !important;
        left: auto !important;
        right: 0 !important;
        max-width: inherit !important;
      }

      /deep/ .dropdown-item {
        padding: 0.6rem 20px 0.6rem 28px;
      }

      /deep/ .dropdown-item.active::before {
        top: 12px;
      }

      @media only screen and (min-width: 1280px) {
        display: none;
      }

      .dropdown-toggle .icon {
        font-size: 6px;
        position: relative;
        top: -1px;
        left: 2px;
      }
    }
  }

  .post-top {
    display: flex;
  }

  .small-icon {
    font-size: 12px;
    position: relative;
    top: -2px;
    margin-left: 24px;
  }

  .post-children {
    margin-left: auto;
    display: flex;
  }

  .post-children .post-child {
    height: 44px;
    width: 44px;
  }

  .post-media {
    width: 100%;
  }

  .card {
    border-width: 0;
    background-color: $color-grey-light;
    margin-bottom: 5px;
    padding: 10px;

    @include breakpoint-lg() {
      padding: 10px 0;
    }

    .notice-boards & {
      background: $color-white;
    }
  }

  .card-body {
    padding: 1.25rem 0;

    .notice-boards & {
      padding: 0;
    }
  }

  .birthday-tab {
    padding: 20px;
  }

  .birthday-item {
    margin: 10px;
    padding: 20px;
    background: $color-white;
  }

  .b-nav-dropdown {
    /deep/ a {
      position: relative;
      z-index: 1003;
      padding: 0.8rem;
      padding-left: 1.1rem !important;
      font-size: 13px;
      text-transform: uppercase;

      span:not(.small-icon) {
        padding-left: 1.2rem;
        margin-right: 0.5rem !important;
      }
    }

    /deep/ .dropdown-menu {
      margin-top: -52px;
      max-width: 275px !important;
      width: 275px;
    }

    /deep/ .dropdown-menu-right {
      z-index: 1002;
    }

    .dropdown-item {
      padding-left: 0.5rem;

      i {
        width: 20px;
        display: inline-block;
        text-align: center;
        font-size: 22px;
        margin-top: -4px;
        float: left;
      }

      a:first-child {
        margin-top: 0.75rem;
        padding-left: 1.35rem !important;
      }
    }

    /deep/ > a {
      padding-top: 13px;
      float: right;
      height: 50px;

      button {
        font-weight: bold;
        text-transform: uppercase;
        color: $color-primary-dark-employee !important;

        .theme-guardian & {
          color: $color-primary-dark-guardian !important;
        }

        .theme-child & {
          color: $color-primary-dark-child !important;
        }
      }
    }

    /deep/ > div {
      min-width: 13em;
    }

    &.dropdown-select {
      width: 300px;
      text-align: left;
      list-style-type: none;

      &.show {
        .icon-Aula_close {
          margin-top: 0 !important;
        }

        .icon-Aula_down-arrow {
          display: none;
        }

        > a {
          padding-top: 0.5rem;
        }
      }

      &:not(.show) {
        .icon-Aula_close {
          display: none;
        }
      }

      span.small-icon {
        float: right;
      }

      .icon-Aula_close {
        margin-left: 15px !important;
        margin-right: 0 !important;
      }

      .icon-Aula_down-arrow {
        margin-bottom: 0 !important;
        margin-top: 12px !important;
        margin-right: 8px !important;
      }
    }

    .nav-link .btn {
      padding-right: 50px;
      padding-top: 3px;

      > .small-icon {
        position: absolute;
        right: 20px;
        top: 20px;

        &.icon-Aula_down-arrow {
          top: 10px;
          right: 10px;
          font-size: 8px;
        }
      }
    }
  }

  .b-nav-dropdown:not(.show) /deep/ > a {
    border-radius: 5px;

    button {
      color: #fff !important;
    }

    .theme-employee & {
      background-color: $color-primary-darker-employee;
    }

    .theme-child & {
      background-color: $color-primary-darker-child;
    }

    .theme-admin & {
      background-color: $color-primary-darker-admin;
    }

    .theme-guardian & {
      background: $color-primary-darker-guardian;
    }
  }
}

.theme-guardian {
  #aula-posts {
    .config-sections {
      border-color: $color-primary-darker-guardian;

      .nav-item {
        color: $color-primary-dark-guardian;

        .active,
        &:hover {
          color: $color-primary-darker-guardian;
        }

        .active::after {
          border-color: $color-primary-darker-guardian transparent transparent transparent;
        }
      }
    }
  }
}

.theme-employee {
  #aula-posts {
    .config-sections {
      border-color: $color-primary-darker-employee;

      .nav-item {
        color: $color-primary-dark-employee;

        .active,
        &:hover {
          color: $color-primary-darker-employee;
        }

        .active::after {
          border-color: $color-primary-darker-employee transparent transparent transparent;
        }
      }
    }
  }
}

.theme-child {
  #aula-posts {
    .config-sections {
      border-color: $color-primary-darker-child;

      .nav-item {
        color: $color-primary-dark-child;

        .active,
        &:hover {
          color: $color-primary-darker-child;
        }

        .active::after {
          border-color: $color-primary-darker-child transparent transparent transparent;
        }
      }
    }
  }
}
