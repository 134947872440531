























































































































































.widget-W0025V0002 {
/* Spinner is centered horizontally and vertically within the parent */
.fa-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

/* No scrolling in alerts */
.alert {
  overflow: hidden;
}

.card {
  margin-top: 10px;
}

p {
  font-size: 12px;
}

pre {
  white-space: pre-line; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */

  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #222350;
  margin-bottom: 0;
}

/* The date of the announcement is the subtitle of the card */
.card-subtitle {
  text-transform: capitalize;
  font-size: 14px;
}

/* The title of the announcement */
.card-title, .error-title {
  font-size: 16px;
}

.clickable {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.fa-sync {
  margin-left: 6px;
}

/* SVG icon */
.Arched_x0020_Green {
  fill: url(#SVGID_1_);
  stroke: #FFFFFF;
  stroke-width: 0.25;
  stroke-miterlimit: 1;
}

.st0 {
  fill: #49A2C2;
}

.st1 {
  fill: #5EAFD4;
}

.st2 {
  fill: #96C5DB;
}

.hh-icon {
  height: 32px;
  margin-right: 8px;
}

.widget-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
}
