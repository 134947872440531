$app-color-base: #222350;

$color-primary-darker: #004651;
$color-primary-dark: #007a8d;
$color-primary-base: #2091a2;
$color-primary-light: #45b7c1;

/* THEME - admin */
$color-primary-darker-admin: #004651;
$color-primary-dark-admin: #007a8d;
$color-primary-base-admin:#e9eef1;
$color-primary-light-admin: #d9e3e9;

/* THEME - employees */
$color-primary-darker-employee: #004651;
$color-primary-dark-employee: #007a8d;
$color-primary-base-employee: #2091a2;
$color-primary-light-employee: #45b7c1;

/* THEME - guardians */
$color-primary-darker-guardian: #19425f;
$color-primary-dark-guardian: #18638f;
$color-primary-base-guardian: #347da7;
$color-primary-light-guardian: #549ec7;

/* THEME - child */
$color-primary-darker-child: #19425f;
$color-primary-dark-child: #18638f;
$color-primary-base-child: #347da7;
$color-primary-light-child: #549ec7;

/* Bacground colors */
$color-grey-dunkel: #c8d6df;
$color-grey-darker: #d9dfe3;
$color-grey-dark: #d9e3e9;
$color-grey-medium: #e1e9ee;
$color-grey-base: #e9eef1;
$color-grey-light: #f6f7f8;
$color-grey-modal-light: #f5f3f4;
$color-grey-lighter: #fbfbfb;
$color-grey-dim: #727172;

$color-white: #fff;
$color-black: #000000;
$color-grey: #808080;
$color-grey-darkest: #ccc;
$color-darkblue: #004651;
$color-blue: #2f729a;
$color-lightblue: #549ec7;

/* Text colors */
$color-help-text: #727172;
$color-error-text: #9b3159;
$color-conversation-left: #4a4a4a;
$color-picker-button: #1b90a3;
$color-picker-button-green: #2091a2;
$color-picker-button-blue: #347da7;
/* Colors */
$color-alert: #b50050;
$overlay-background-color: #404050;
$color-icon-button: #347da7;
$color-attention: #f8ce63;
$color-dark-grey: #6c757d;

/* Form colors */
$color-form-border: #727172;
$color-input-focus: #fbfbfb;
$color-dropdown-focus: #f3f3f3;
$color-date-text: #15324c;
$color-date-weekday: #828993;
$color-select-all: #1a183f;
$color-input-focus-background: #8d939e;
$checkbox-disable-color: $color-dark-grey;

/* Other colors */
$color-read-message: #d9e5e7;
$color-bg-black: #000;

/* Sizes */
$layout-left-narrow: 120px;
$layout-right-normal: 280px;
$layout-right-wide: 360px;
$menu-link-height: 55px;

$group-tooltip-background: #fff;
$group-tooltip-border: $color-primary-darker;
$group-tooltip-text: $color-primary-darker;

$header-topbar-height: 58px;
$header-topbar-height-lg: 80px;
$header-topbar-height-xl: 120px;

$subscription-container-height: 80px;
$subscription-container-height-small: 60px;

/* Z-index */
$modal-z-index: 1055; /* Must be lower than $sub-modal-z-index */
$sub-modal-z-index: 1056;
$user-img-z-index: 100;
$modal-sticky-header-z-index: 101; /* Must have a higher than $user-img-z-index */
$media-information-overlay-z-index: 1000; /* Must have a lower z-index than aula-modal's */
$media-icon-button-z-index: 999; /* Must have a lower z-index than attachment dropdown's (1000) */
$header-top-menu-z-index: 1031;
$header-top-menu-dropdown-open-z-index: 1054; /* Must have a higher z-index value than $multi-button-mobile-container-z-index */
$presence-toolbar-mobile-menu-header: 1051; /* Must have a higher z-index value than $header-top-menu-z-index */
$impersonate-header-z-index: 1051; /* Must have a higher z-index value than  $header-top-menu-z-index */
$multi-button-mobile-container-z-index: 1052; /* Must have a higher z-index value than $mobile-gallery-toolbar-z-index */
$multi-button-mobile-backdrop-container-z-index: 1051; /* Must have a lower z-index value than  $multi-button-mobile-container-z-index */
$multi-button-mobile-in-group-container-z-index: 2050; /* Must have a bigger index than $multi-button-mobile-in-group-backdrop-container-z-index*/
$multi-button-mobile-in-group-backdrop-container-z-index: 2000;
$department-select-z-index: 1051; /* Must have higher z-index than the top menu as it's positioned in the same area as the top menu */
$consent-header-mobile-z-index: 1054; /* Must be higher than $aula-menu-mobile-z-index */
$header-dropdown-menu-mobile-z-index: 1055;
$aula-modal-button-z-index: 1052;
$aula-modal-open-z-index: 1060; /* Modal should be higher than any other z-index element on this list */
$aula-group-modal-backdrop-z-index: 1054;
$aula-group-modal-z-index: 1055; /* Should be higher than $aula-group-modal-backdrop-z-index */
$mobile-calendar-toolbar-z-index: 1053; /* Must have a higher z-index value than $header-top-menu-z-index & $multi-button-mobile-container-z-index */
$mobile-gallery-toolbar-z-index: 1051; /* Must have a higher z-index value than $header-top-menu-z-index */
$administration-user-list-dropdown-z-index: 1051; /* Should be same or higher than $header-top-menu-z-index */
$prd-header-mobile-z-index: 1051; /* Should be higher than $header-top-menu-z-index */
$aula-menu-desktop-z-index: 1035;
$aula-menu-mobile-z-index: 1053;
$aula-presence-mobile-z-index: 1051; /* Must have a higher z-index value than $header-top-menu-z-index */
$filter-dropdown-z-index: 1002;
$gallery-conversion-pending-overlay: 1000; /* if conversion is pending, this must be placed over the thumbnail */
