













































































































































































































































































































.widget-W0067V0001 {
.fravaersWidget {
  overflow: hidden;
}
.fravaer-title {
  margin-top: 16px;
  background-color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  text-align: center;
  min-height: 34px;
  padding-top: 5px;
  margin-bottom: -1px;
}
.fravaer-title h5 {
  margin-bottom: 0 !important;
}
.fravaer-title.titleloading {
  margin-bottom: 0 !important;
}
.fravaer-title.oneSchool.titleloading {
  height: 34px;
}
.fravaer-title.oneSchool {
  height: 11px;
  min-height: 0;
}
.fravaer-top,
.fravaer-middle,
.fravaer-bottom {
  margin-top: 1px;
  background-color: white;
  padding: 5px;
}
.fravaer-bottom {
  margin-bottom: 5px;
  padding-bottom: 25px;
}
.badge {
  margin-left: 1px;
  line-height: 2;
  margin-bottom: 1px;
}
h6 .fat {
  font-weight: bolder !important;
}
.laererColor {
  background-color: #2091a2;
  color: white;
}
.laererColor.laererTidColor {
  background-color: #45b7c1;
  color: white;
}
.school-badge {
  background-color: #549ec7;
  color: white;
}
.school-name {
}
.akuBadge {
  background-color: #222350;
  color: white;
}
.changesColor {
  background-color: #007a8d;
  color: white;
}
.cachedTime {
  position: relative;
  float: right;
  color: grey;
  bottom: -5px;
  font-size: 13px;
}
.bottom-row {
  padding-bottom: 18px;
}

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}
hr {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

h5 {
  font-weight: bold;
}

.df-datepicker {
  display: grid;
  grid-template-columns: 100px auto 100px;
  width: 100%;
}

.df-date {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.df-date-button button {
  width: 55px;
  height: 30px;
  color: white;
}

.date-forward {
  display: flex;
  direction: rtl;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #222350;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #222350, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #222350, 0.5em 0 0 #222350;
  }
}
}
