













































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
.aula-widget {
  .quick-access-item {
    margin-top: 0 !important;
    height: 70px;
    line-height: 70px;
    padding: 0 25px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 15px;
    background: #ffffff;
    form {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .widget-icon img {
    margin-left: -15px;
    max-width: 50px;
    max-height: 50px;
    margin-right: 5px;
  }
  .widgetBox {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      padding-bottom: 0.25rem;
      color: var(--color-primary-light);
      .widget-name {
        font-size: 1rem;
        font-weight: bold;
        flex-grow: 1;
        color: var(--color-primary-darker);
      }
      .widget-supplier {
        font-size: 0.7rem;
        color: var(--color-primary-darker);
      }
    }
    .id {
      display: none;
    }
    .content {
      border: solid 1px $color-primary-light-guardian;
      .theme-employee & {
        border-color: $color-primary-light-employee;
      }
      padding: 5px;
    }
  }
  button {
    border: 0;
    text-align: left;
  }
}
