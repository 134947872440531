







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.partially-available-resource-list {
  list-style: none;
  margin: 8px 0 0 0;
  padding: 0;
}
.mobile-settings-section {
  margin-bottom: 25px;

  > div:last-child {
    border-bottom: 1px solid var(--color-white);
    padding-bottom: 25px;
  }
}
.mobile-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    @include breakpoint-lg-down() {
      font-weight: normal !important;
    }
  }
}

.event-settings {
  height: 40px;
  display: flex;
  align-items: center;

  label {
    @include breakpoint-lg-down {
      font-weight: normal !important;
    }
  }
}

.planning-assistant-button {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  i {
    font-size: 20px;
    margin-right: 10px;
    text-decoration: none;

    &:hover + span {
      text-decoration: none;
    }
  }

  span {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.input-group-search-addon {
  font-size: 1.5em;
}

.padding-bottom-mobile {
  padding-bottom: 20px;
}

.invited-group-names {
  color: $color-primary-darker !important;
  font-weight: bold;
}

.maxDatePicker {
  margin-left: 10px;
  width: 160px !important;
}

#cannot-send-invitation-modal {
  position: absolute;
  left: 35%;
  top: 30%;
  z-index: 9999;
}

.disabledAddon {
  background: #f5f7fa;
}

#cancel-modal .modal-content {
  height: 210px;
}

.drawer-container {
  position: relative;

  .clickLayer {
    display: none;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.vue__time-picker:focus-visible {
  border: 0;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 0 2px 0.01rem var(--color-primary-darker);
}

.note-label {
  height: 35px;
  display: inline-block;
}

.icon-Aula_search {
  font-size: 18px;
}

.search-module {
  .input-group-prepend {
    --padding-top: 17px;
  }
  /deep/ .aula-search {
    --select-input-tags-padding-right: 28px;
  }
}

.repeating-pattern {
  padding: 10px 0 0 2px;
}
