










.vacation-response-row {
  display: flex;
  padding-top: var(--row-padding-top, 15px);
  padding-bottom: var(--row-padding-bottom, 15px);
  padding-inline-start: var(--row-padding-start, 15px);
  padding-inline-end: var(--row-padding-end, 15px);
}
