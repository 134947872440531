














































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.vacation-date {
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  &:not(:last-of-type) {
    border-bottom: 1px solid $color-grey-dark;
  }
  .view-detail {
    position: relative;
    float: right;
    right: 20px;
    top: 35px;
    padding: 10px;
    i {
      font-size: 16px;
    }
  }
  .vacation-day {
    font-weight: 700;
    margin-bottom: 10px;
    margin-bottom: 10px;
  }

  .vacation-children {
    margin-top: 10px;
    li {
      list-style: none;
      margin-left: -25px;
    }
  }

  .close-day {
    background: var(--color-grey-dark);
    padding: 10px 20px;
    font-weight: 700;
    width: fit-content;
  }
}
