@import '../../core/breakpoints.scss';

.aula-alert-container {
  max-width: 100%;

  .calendar-drawer & {
    margin: 20px;
  }

  .notification-icon {
    color: var(--color-alert);
    vertical-align: middle;
  }

  .alert-text {
    flex: 1;
    width: 1px;
    margin: auto 15px;

    & > span {
      display: flex;

      & .truncatable {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & .alert-text--highlight {
      display: inline;
      color: var(--color-primary-dark);
    }
  }

  .alert-title {
    padding-right: 3px;
    font-weight: 700;
  }

  .alert-cancelled-text {
    padding-right: 3px;
  }

  .alert-text.alert-overflow {
    width: 80%;
    overflow: unset;
    word-break: normal;
    white-space: inherit;

    a {
      color: var(--color-primary-base);
    }

    @include breakpoint-lg {
      &.nowrap {
        white-space: nowrap;
      }
    }
  }

  .class-name {
    display: inline-block;
    width: 30px;
  }

  .aula-alert {
    position: relative;
    display: flex;
    line-height: 20px;
    background: var(--color-grey-base);
    min-height: 50px;
    margin: 5px auto 0;
    border-radius: 5px;
  }

  .aula-alert:hover {
    background: var(--color-grey-dark);
  }

  .aula-alert:first-child {
    margin-top: 0;
  }

  .alert-link {
    font-weight: normal;
    padding: 15px 35px 15px 15px;

    @include breakpoint-xl() {
      width: 150px;
    }

    i {
      position: absolute;
      font-size: 1rem;
      right: 8px;
      top: 15px;

      &.icon-Aula_close {
        top: 15px;
        margin-right: 0.5rem;
      }
    }
  }

  .icon-Aula_export {
    color: var(--color-alert);
    font-weight: bold;
  }

  .icon-Aula_close {
    position: absolute;
    right: 0;
    top: 5px;

    @include breakpoint-lg-down() {
      right: 10px;
      top: 15px;
    }
  }

  .icon-box {
    width: 50px;
    max-height: 100%;
    background-color: var(--color-alert);
    border-radius: 5px 0 0 5px;
    text-align: center;

    i {
      color: var(--color-white);
      font-size: 30px;
      margin: auto;
      top: calc(50% - 15px);
      position: relative;
    }
  }

  .icon-box-exclamation {
    width: 50px;
    max-height: 100%;
    background-color: var(--color-grey-dark);
    border-radius: 5px 0 0 5px;

    .circle {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: var(--color-white);
      margin: auto;
      position: relative;
      top: calc(50% - 17px);
      text-align: center;

      i {
        position: relative;
        top: calc(50% - 8px);
        font-size: 15px;
      }
    }
  }
}
