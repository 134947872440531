


































































































































































































.myWidget /deep/ {
  .nav-pills {
    border-bottom: solid 1px #999;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: none !important;
    border: solid 1px #999;
    border-bottom: solid 1px #fff !important;
    position: relative;
    top: 1px;
    color: #333;
  }

  .tab-pane {
    padding: 20px;
  }
}

input[type=text], input[type=email], select {
  border: solid 1px #ccc;
}

b:before {
  content: "";
  display: block;
}

.breakWord {
  word-break: break-all;
}

a {
  color: #36c;
}

