














































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';

.planning-assistant-calendar {
  /deep/ .fc-resourceTimeline-view {
    .fc-resource-area .fc-scroller {
      overflow: hidden !important;
    }
  }
}

.resizable-container {
  position: absolute;
  display: inline-block;
  top: 0px;
  z-index: 1000;
  width: 4800px;

  .resizable {
    position: absolute;
    z-index: 1000;
    background: $color-alert;
    opacity: 0.2;
    border: 2px solid $color-alert;
    color: white;
    font-size: 20px;
    font-family: sans-serif;
    border-radius: 8px;
    touch-action: none;

    min-height: 50px;

    /* This makes things *much* easier */
    box-sizing: border-box;
  }
}
