










































































































































































































































































































































































































.notification {
  &.interactable {
    cursor: pointer;
  }

  .title {
    font-weight: bold;
  }
  .aula-icon {
    --font-size: 16px;
  }
  .see-more-button {
    --btn-link-color: var(--color-primary-dark);
    font-weight: bold;
  }

  .notification-wrapper {
    display: flex;
    gap: 16px;
  }

  .notification-content {
    flex: 1;
    align-content: center;
  }
  .reply-button {
    font-weight: normal;
    align-self: center;

    .icon-Aula_export {
      color: var(--color-alert);
      font-weight: bold;
      padding-left: 4px;
    }
  }
}
