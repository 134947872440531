






















































































































































































































































































.widget-W0021V0001 {

  * {
    padding: 0;
    margin: 0;
    font-weight: normal;
  }

  .subit-widget-container {
    background-color: #f5f6fa;
    height: 100%;
    min-height: 300px;

    display: flex;
    flex-direction: column;
  }

  .absence-header {
    display: flex;
    flex-direction: column;
    height: 78px;
    background-color: #4d88ff;
    color: white;
    line-height: 55px;
    text-align: left;
  }

  .absence-header-content {
    margin-left: 35px;
    margin-right: 35px;
    text-align: center;
  }

  .absence-header-status-content {
    margin-left: 35px;
    margin-right: 35px;
    display: flex;
    flex-direction: row;
    height: 10px;
    overflow: hidden;
  }

  .absence-header-status-indicator {
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .absence-header-status-circle {
    background-color: #EB5757;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    flex: 1;
    margin-right: 5px;
  }

  .absence-header-content span {
    padding-left: 10px;
    vertical-align: middle;
    font-weight: bold;
    font-size: 16px;
  }

  .absence-header-content svg {
    width: 12px;
  }

  .cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .absence-item-main {
    height: 55px;
    background-color: #FBFBFF;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .absence-item__teacher-initials {
    background-color: #EB5757;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 17px;
    font-size: 12px;
    color: white;
    margin-left: 9px;
    margin-right: 5px;
  }

  .absence-item__teacher-initials--covered {
    background-color: #37BD6F;
    color: #ffffff;
  }

  .absence-item__teacher-initials--partly-covered {
    background-color: #F2C94C;
    color: #ffffff;
  }

  .absence-item__absence-period {
    display: flex;
    line-height: 17px;
    font-size: 12px;
    margin-right: 5px;
  }

  .absence-item__lessons {
    margin-right: 20px;
    background: lightgray;
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    margin-left: auto;
  }

  .absence-item__absence-period-spacing {
    margin: 0 5px 0 5px;
  }

  .calendar-left, .calendar-right {
    width: 35px;
    overflow: hidden;
    position: absolute;
    height: 55px;
    box-sizing: border-box;
    transition: all ease-in-out .3s;
    z-index: 61;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .calendar-right {
    right: 0;
  }

  .calendar-left {
    left: 0;
  }

  .absence-content {
    width: 100%;
    height: 100%;
    max-height: 220px;
    overflow-y: auto;

    flex-grow: 1;
  }

  .calendar-arrow-left {
    border: solid #4f4f4f;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .calendar-arrow-right {
    border: solid #4f4f4f;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .loading {
    margin: 10px auto 0;
    text-align: center;
  }

  .footer {
    background: #F5F6FA;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
  }

  .footer .footer-item {
    margin-left: 14px;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .footer .footer-item .footer-item-icon {
    fill: #6F7785;
    height: 14px;
    margin-right: 8px;
  }

  .footer .footer-item .footer-item-text {
    color: #6F7785;
    font-size: 12px;
  }

}
