






























































































































































































































































































































.widget-W0018V0001 {
/*bootstrap overrides*/
.easyiq-wp-classesDropdown /deep/ .dropdown-menu
{
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  font-size:0.9em;
}
/*bootstrap overrides end*/
.easyiq-link-links {
  background-color:#FFF;
}
.easyiq-link-panelheader {
  font-size: 1.3em;
  text-transform: uppercase;
  color: #12798c;
  margin:10px;
  cursor:pointer;
}

.easyiq-link-panelheader /deep/ button{
  background:transparent;
  padding:5px;
  margin:3px;

}
.easyiq-panel-header{

}
.easyiq-panel-openicon{
  font-size:1em;
}

.easyiq-link-panel div[data-image] {
  margin-top: 5px;
  height: 48px;

}

.easyiq-link-panel img {
  vertical-align: middle;
  max-height: 48px;
  max-width: 100%;
  width: unset !important;

}
.easyiq-link-panel div[data-title] p{
  max-height: 45px;
  overflow-y: hidden;
  font-size: 0.8em;
}

.easyiq-link-panel {
  height: 100px;
  width: 100px;
  margin: 5px 5px 5px 0;
  padding:5px;
  text-align: center;
  border: 1px dotted #c1c1c1;
  border-radius:5px;
  -webkit-border-radius:5px;
}

.easyiq-link-panelcontainer {
  background-color: #FFF;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.easyiq-link-panelcontainer[data-placement='narrow'] {
  background-color: #FFF;
  display: unset;
}

.easyiq-link-panel[data-placement='narrow'] {
  height: unset;
  width: unset;
  margin: unset;
  text-align: unset;

  display: flex;
  align-items: center;
  padding: 0 5px !important;
  border:unset;
}
.easyiq-link-panelheader img
{
  margin-right:10px;
  height:32px;
}

.easyiq-link-panelheader img[data-placement='narrow']
{
  height:16px;
}

.easyiq-link-ListItemColoredBar {
  height: 7px;
  margin-top: -7px;
  margin-bottom: 2px;
  position: relative;
  z-index: 1;
  background-color: #17a2b8 !important;
}

.easyiq-link-ListItemColoredBar[data-placement='narrow'] {
  margin-top:0;
}

.easyiq-link-panel[data-placement='narrow'] img {
  max-height: 16px;
  max-width: 100%;
  width: unset !important;
}
.easyiq-link-panel[data-placement='narrow'] div[data-title] p {
  overflow-x: hidden;
  font-size: 0.8em;
  margin:0;
  justify-content:center;
  padding:0 5px;
}
.easyiq-link-panelheader[data-placement='narrow'] {
  font-size: 1em;
  text-transform: uppercase;
  padding: 0 10px;
  color: #12798c;
  margin:5px;
}
.easyiq-link-panel[data-placement='narrow'] div[data-img]
{
  align-items:center;
  width:40px;
  min-width:40px;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.lds-css {
  width: 240px;
  height: 240px;
  margin: auto;
  transform: scale(0.7);
}

.lds-ring {
  height: 100%;
  width: 100%;
}

.lds-ring > div {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 10px solid #fff;
  border-color: #fff transparent transparent transparent;
  animation: lds-ring 1.5s cubic-bezier(0.5,0,0.5,1) infinite;
}

.lds-ring > div:nth-child(1) {
  border-color: rgba(0, 122, 141, 0.25);
  animation: none;
}

.lds-ring > div:nth-child(3) {
  animation-delay: .19s;
}

.lds-ring > div:nth-child(4) {
  animation-delay: .38s;
}

.lds-ring > div:nth-child(5) {
  animation-delay: .57s;
}
}
