























































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/presence-employee';

.maxDatePicker {
  margin-left: 10px;
  width: 160px !important;
}

#cannot-send-invitation-modal {
  position: absolute;
  left: 35%;
  top: 30%;
  z-index: 9999;
}

.disabledAddon {
  background: #f5f7fa;
}

#cancel-modal .modal-content {
  height: 210px;
}
