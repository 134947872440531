





















































































































































































































































































































































































































.widget-W0004V0001 {
  .widget-loader {
    font-family: "Lato", "Avenir", Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin: 20px 0
  }

  .widget-loader > .fa {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: both;
    margin-right: 10px
  }

  @keyframes spin {
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(360deg)
    }
  }

  .errors {
    margin-top: 20px
  }

  .errors__error {
    display: block;
    padding: 15px 20px;
    background-color: #fff;
    color: #b50050
  }

  .no-tasks {
    display: block;
    text-align: center;
    padding: 10px
  }

  .widget-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #19405a;
    text-align: left;
    padding: 21px 10px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px
  }

  .widget-title h2 {
    margin: 10px 20px 10px 0;
    font-size: 32px;
    font-weight: 900;
    line-height: 34px;
    text-transform: uppercase
  }

  .widget-title h3 {
    margin: 0;
    vertical-align: bottom;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px;
    margin-top: 15px;
    margin-bottom: 10px
  }

  .studentname {
    margin-bottom: 10px;
    font-size: 22px
  }

  .widget-nav {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    color: #19405a;
    text-align: left
  }

  .widget-nav span {
    margin: 7px 4px 7px 4px;
    vertical-align: bottom;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px
  }

  .widget-nav__week-info {
    margin: 7px 10px 7px 10px;
    text-transform: uppercase
  }

  .widget-nav__week-link {
    font-size: 30px;
    font-weight: 700;
    cursor: pointer
  }

  .weekplan {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -8px
  }

  .weekplan__column {
    flex: 1 0 220px;
    width: 20%;
    max-width: 20%;
    border-left: 8px solid rgba(0, 0, 0, 0);
    margin-bottom: 20px
  }

  .weekplan__column span {
    font-size: 15px;
    font-weight: 500
  }

  .weekplan__column__head {
    background-color: #19405a;
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 10px
  }

  .weekplan__column__head:first-child {
    border-left: 0
  }

  .weekplan-task {
    background-color: #b4cdcf;
    padding: 8px 15px 8px 15px;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 35px 35px
  }

  .weekplan-task__pill {
    display: inline-block;
    margin-right: 3px;
    text-align: center;
    background-color: #589ea5;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border-radius: 10px;
    padding: 2px 12px;
    overflow: hidden
  }

  .weekplan-task--task {
    background-color: #f4e1e2;
    background-size: 18px;
    background-position: 96% 8%
  }

  .weekplan-task--task .weekplan-task__pill {
    background-color: #e46256
  }

  .weekplan-task--task .js-button {
    color: #e46256
  }

  .weekplan-task--task .js-button:hover {
    color: #efbbb8 !important
  }

  .weekplan-task--task hr {
    border-top-color: #efbbb8 !important
  }

  .weekplan-task--assignment {
    background-color: #f4e1e2;
    background-size: 18px;
    background-position: 96% 8%
  }

  .weekplan-task--assignment .weekplan-task__pill {
    background-color: #e46256
  }

  .weekplan-task--assignment .js-button {
    color: #e46256 !important
  }

  .weekplan-task--assignment hr {
    border-top-color: #efbbb8 !important
  }

  .weekplan-task--bottom {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: 4px
  }

  .weekplan-task--bottom .link-icon {
    height: 18px;
    width: 18px;
    background-size: contain
  }

  .weekplan-task .link-hr {
    margin-top: 5px
  }

  .weekplan-task__toggle-container {
    position: relative;
    padding-top: 5px;
    cursor: pointer
  }

  .weekplan-task__toggle-container:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
  }

  .weekplan-task__toggle-container__button {
    text-decoration: underline;
    display: none
  }

  .weekplan-task__toggle-container__button.show {
    display: block
  }

  .weekplan-task--assignment .weekplan-task__toggle-container:hover .weekplan-task__toggle-container__button {
    color: #e46256
  }

  .weekplan-task__toggle-container__content {
    display: block;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    width: 100%;
    line-height: 17px;
    padding: 0;
    transition: height .3s ease;
    cursor: pointer;
    word-break: break-word;
    white-space: pre-line
  }

  .weekplan-task__toggle-container__content.open {
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    text-overflow: unset
  }

  .weekplan-task__title {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 0px
  }

  .weekplan-task__link {
    color: #2c3e50;
    cursor: pointer;
    text-decoration: underline;
    background-size: 18px;
    background-position: 1px 2px;
    background-repeat: no-repeat
  }

  .weekplan-task__top {
    position: relative;
    min-height: 24px
  }

  .weekplan-task__top .pills-wrap {
    max-width: 94%
  }

  .weekplan-task__top .event-icon {
    background-size: 18px;
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    right: 0
  }

  .weekplan-task hr {
    height: 1px;
    width: calc(100% + 30px);
    border: 0;
    border-top: 1px solid #589ea5;
    margin: 1px -15px 1px -15px
  }

  .weekplan-task--comment {
    background-size: 18px;
    top: 5px
  }

  @media(max-width: 920px) {
    .studentplan-widget {
      margin: auto;
      max-width: 440px
    }
    .weekplan-container:last-of-type {
      margin-bottom: 20px
    }
    .plandate, .studentname {
      display: none
    }
    .childname {
      display: block
    }
    .weekplan-title {
      width: 50%;
      margin-top: 5px;
      display: inline-block;
      font-weight: 500;
      font-size: 20px
    }
    .widget-nav {
      width: 50%;
      font-weight: 500;
      float: right;
      display: inline-block;
      margin-top: 4px;
      text-align: right
    }
    .widget-title h2 {
      margin: 10px 20px 10px 0
    }
    .widget-title h2, .widget-nav span {
      font-size: 18px
    }
    .widget-title h3 {
      display: block;
      width: 100%
    }
    .widget-nav__week-link, .widget-nav span {
      margin-top: 10px
    }
    .weekdays {
      padding-bottom: 10px;
      width: 100%;
      display: inline-block
    }
    .weekdays__day {
      border-right: 1px solid #bebebe;
      height: auto;
      width: 20%;
      text-align: center;
      padding: 10px;
      background-color: #eeeff1;
      color: #19405a;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      display: inline-block
    }
    .weekdays__day:last-of-type {
      border-right: none
    }
    .weekdays__day.active {
      background-color: #19405a;
      color: #fff
    }
    .weekdays .circle {
      display: inline-block;
      width: 20%;
      height: auto;
      text-align: center;
      visibility: hidden
    }
    .weekdays .circle.visible {
      visibility: visible
    }
    .weekplan-container:not(:first-child) .weekplan__column__head {
      margin-top: 10px !important
    }
    .weekplan__column {
      display: none;
      width: 100%;
      max-width: none;
      margin: auto
    }
    .weekplan__column.active {
      display: block;
      width: 100%;
      max-width: none
    }
    .weekplan-container {
      max-width: 440px;
      margin: auto
    }
    .weekplan__column__tasks span {
      display: block;
      font-size: 16px;
      font-weight: 500
    }
  }
  @media(min-width: 920px) {
    .weekdays, .childname {
      display: none
    }
  }

  .narrow .studentplan-widget {
    margin: auto;
    max-width: 440px
  }

  .narrow .weekplan-container:last-of-type {
    margin-bottom: 20px
  }

  .narrow .plandate, .narrow .studentname {
    display: none
  }

  .narrow .childname {
    display: block
  }

  .narrow .weekplan-title {
    width: 50%;
    margin-top: 5px;
    display: inline-block;
    font-weight: 500;
    font-size: 20px
  }

  .narrow .widget-nav {
    width: 50%;
    font-weight: 500;
    float: right;
    display: inline-block;
    margin-top: 4px;
    text-align: right
  }

  .narrow .widget-title h2 {
    margin: 10px 20px 10px 0
  }

  .narrow .widget-title h2, .narrow .widget-nav span {
    font-size: 18px
  }

  .narrow .widget-title h3 {
    display: block;
    width: 100%
  }

  .narrow .widget-nav__week-link, .narrow .widget-nav span {
    margin-top: 10px
  }

  .narrow .weekdays {
    padding-bottom: 10px;
    width: 100%;
    display: inline-block
  }

  .narrow .weekdays__day {
    border-right: 1px solid #bebebe;
    height: auto;
    width: 20%;
    text-align: center;
    padding: 10px;
    background-color: #eeeff1;
    color: #19405a;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block
  }

  .narrow .weekdays__day:last-of-type {
    border-right: none
  }

  .narrow .weekdays__day.active {
    background-color: #19405a;
    color: #fff
  }

  .narrow .weekdays .circle {
    display: inline-block;
    width: 20%;
    height: auto;
    text-align: center;
    visibility: hidden
  }

  .narrow .weekdays .circle.visible {
    visibility: visible
  }

  .narrow .weekplan-container:not(:first-child) .weekplan__column__head {
    margin-top: 10px !important
  }

  .narrow .weekplan__column {
    display: none;
    width: 100%;
    max-width: none;
    margin: auto
  }

  .narrow .weekplan__column.active {
    display: block;
    width: 100%;
    max-width: none
  }

  .narrow .weekplan-container {
    max-width: 440px;
    margin: auto
  }

  .narrow .weekplan__column__tasks span {
    display: block;
    font-size: 16px;
    font-weight: 500
  }

  @media only screen and (max-width: 1300px) and (min-width: 980px) {
    .medium .studentplan-widget {
      margin: auto;
      max-width: 440px
    }
    .medium .weekplan-container:last-of-type {
      margin-bottom: 20px
    }
    .medium .plandate, .medium .studentname {
      display: none
    }
    .medium .childname {
      display: block
    }
    .medium .weekplan-title {
      width: 50%;
      margin-top: 5px;
      display: inline-block;
      font-weight: 500;
      font-size: 20px
    }
    .medium .widget-nav {
      width: 50%;
      font-weight: 500;
      float: right;
      display: inline-block;
      margin-top: 4px;
      text-align: right
    }
    .medium .widget-title h2 {
      margin: 10px 20px 10px 0
    }
    .medium .widget-title h2, .medium .widget-nav span {
      font-size: 18px
    }
    .medium .widget-title h3 {
      display: block;
      width: 100%
    }
    .medium .widget-nav__week-link, .medium .widget-nav span {
      margin-top: 10px
    }
    .medium .weekdays {
      padding-bottom: 10px;
      width: 100%;
      display: inline-block
    }
    .medium .weekdays__day {
      border-right: 1px solid #bebebe;
      height: auto;
      width: 20%;
      text-align: center;
      padding: 10px;
      background-color: #eeeff1;
      color: #19405a;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      display: inline-block
    }
    .medium .weekdays__day:last-of-type {
      border-right: none
    }
    .medium .weekdays__day.active {
      background-color: #19405a;
      color: #fff
    }
    .medium .weekdays .circle {
      display: inline-block;
      width: 20%;
      height: auto;
      text-align: center;
      visibility: hidden
    }
    .medium .weekdays .circle.visible {
      visibility: visible
    }
    .medium .weekplan-container:not(:first-child) .weekplan__column__head {
      margin-top: 10px !important
    }
    .medium .weekplan__column {
      display: none;
      width: 100%;
      max-width: none;
      margin: auto
    }
    .medium .weekplan__column.active {
      display: block;
      width: 100%;
      max-width: none
    }
    .medium .weekplan-container {
      max-width: 440px;
      margin: auto
    }
    .medium .weekplan__column__tasks span {
      display: block;
      font-size: 16px;
      font-weight: 500
    }
  }
}
