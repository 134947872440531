




































































































































































































































































































































































































































.widget-W0071V0001 {
.fravaer-bottom {
  margin-top: 1px;
  background-color: white;
  padding: 5px;
}
.fravaer-bottom {
  margin-bottom: 5px;
  padding-bottom: 25px;
}
.leftBorderTd {
  min-width: 40px;
  border-left: 1px solid #dee2e6;
}

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

h5 {
  font-weight: bold;
}

tbody tr td {
  color: black;
}
tbody tr.changedRow td {
  background-color: #e9eef1;
  color: #222350 !important;
}
tbody tr.changedRow.akuRow td {
  background-color: #d0dfe3;
  color: #222350 !important;
}
tbody tr.changedRow td + td {
  border-left: 1px solid lightgray;
}
tbody tr.changedRow + tr.changedRow td {
  border-top: 1px solid lightgray !important;
}
.table thead th {
  border-bottom: 1px solid #dee2e6;
}
table.table {
  margin-bottom: 0;
}
.nameContainer {
  min-height: 46px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  color: #222350;
  font-weight: bold;
}
.nameContainer.period {
  white-space: nowrap;
}
.kort {
  margin-bottom: 15px !important;
}
@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #222350;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #222350, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #222350, 0.5em 0 0 #222350;
  }
}

h2,
h6 {
  display: inline;
}

h5 {
  margin-bottom: 0;
}

.card.title-card {
  padding: 0;
}
.card.kort {
  padding: 5px;
}
.top-bar {
  margin-bottom: 5px;
}
.df-datepicker {
  display: grid;
  grid-template-columns: 100px auto 100px;
  width: 100%;
}

.df-date {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.df-date-button button {
  width: 55px;
  height: 30px;
  color: white;
}

.date-forward {
  display: flex;
  direction: rtl;
}
}
