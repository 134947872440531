













































































































































































































































































































































































































































































































.widget-W0030V0001 {
/* Class implemented by NC due to difference between style in preview and production.
Hopefully this will be fixed in the future */
.assignment-list h1 {
  font-size: 36px;
  font-weight: 900;
  margin: 0;
}

/* Class implemented by NC due to difference between style in preview and production.
Hopefully this will be fixed in the future */
.assignment-list .container-fluid {
  padding: 0 !important;
}

.assignment-list .assignment-list-header-container .assignment-list-header-small,
.assignment-list .assignment-list-header-container .assignment-list-header-week-label {
  font-size: 18px !important;
  text-transform: uppercase;
}

.assignment-list .assignment-list-header-container .assignment-list-header-large {
  font-size: 36px !important;
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker {
  background-color: transparent;
  color: #222350;
  width: 30px;
  padding: 0;
  height: 30px;
  border-radius: 50%;
  position: relative;
  align-items: center;
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker .fa-caret-left {
  margin-right: 3px
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker .fa-caret-right {
  margin-left: 3px;
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker:active {
  background-color: #828a9180 !important;
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker:focus {
  box-shadow: none !important;
}

.assignment-list .assignment-list-container .assignment-assignee {
  margin-top: 16px;
}

.assignment-list .assignments {
  list-style: none;
  padding-left: 0;
}

.assignment-list .assignment-tab {
  text-align: center;
  line-height: 16px;
}

.assignment-list .groups {
  list-style: none;
  padding-left: 0;
  line-height: 1 !important;
}
.assignment-list .group-name {
  display: inline-block;
  margin-right: 4px;
}
.assignment-list .assignment-timestamp-tag {
  border-radius: 4px;
  margin-right: 4px;
}
.assignment-list .assignment-timestamp-tag .assignment-timestamp {
  margin-right: 2px;
}
.assignment-list .assignment-link-btn {
  padding: 0;
  color: #007a8d;
  background: transparent;
}
.assignment-list .assignment-link-btn.complete {
  text-decoration: line-through;
  opacity: 0.5;
}
.assignment-list .assignment-link-btn:focus {
  box-shadow: none !important;
}
.assignment-list-medium .assignment,
.assignment-list-narrow .assignment {
  margin-bottom: 16px;
}
.assignment-list .assignment-indicator {
  margin-top: -4px;
}
.assignment-list-medium {
  padding-top: 20px;
}
.assignment-list-medium .days {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.assignment-list-medium .day-title {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  background: #45b7c1;
  text-align: center;
  padding: 17px 0;
  margin-bottom: 8px;
}
.assignment-list-medium .today {
  background: #222350;
}
.assignment-list-medium .assignment-list-container {
  padding: 0 16px 16px 16px;
}
.assignment-list-medium .day-container {
  margin-right: 2px;
  min-width: 0;
}
.assignment-list-medium .day-container .assignment-link-btn-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.assignment-list-medium .day-container:last-child {
  margin-right: 0;
}
.assignment-list-narrow {
  margin-top: 10px;
}
.assignment-list-narrow .nav.nav-pills.card-header-pills {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.assignment-list-narrow .no-assignments {
  padding: 16px 0;
}
.assignment-list-narrow .card-body {
  padding: 0 16px;
}
.assignment-list .mu-provider-narrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
}
.assignment-list .mu-provider-text {
  font-size: 12px;
  color: #868686;
}
.assignment-list .mu-provider-btn {
  background-color: transparent;
  padding: 0 0 0 8px;
  border: none;
  border-radius: 0;
}
.assignment-list .mu-provider-btn:active {
  background-color: transparent;
  border-color: transparent;
}
.assignment-list .mu-provider-btn:focus {
  box-shadow: none;
}
.assignment-list .mu-provider-img {
  height: 30px;
}
.error-message {
  color: #b50050;
}
}
