






















































































































































































































































































































.widget-W0029V0001 {
/* Class implemented by NC due to difference between style in preview and production.
Hopefully this will be fixed in the future */
.note-list h1 {
  font-size: 36px;
  font-weight: 900;
  margin: 0;
}

/* Class implemented by NC due to difference between style in preview and production.
Hopefully this will be fixed in the future */
.note-list .container-fluid {
  padding: 0 !important;
}

.note-list .spinner-wrapper {
  min-height: 42px;
  padding: 10px 0;
}

.note-list .message-container {
  margin: 0;
  padding: 10px 0;
}

.note-list .error-message {
  color: #b50050;
}

.note-list .info-text {
  font-size: 12px;
}

.note-list .note-list-header .note-list-header-small,
.note-list .note-list-header .note-list-header-week-label {
  font-size: 18px !important;
  text-transform: uppercase;
}

.note-list .note-list-header .note-list-header-large {
  font-size: 36px !important;
}

.note-list .note-list-header .note-list-header-week-picker {
  background-color: transparent;
  color: #222350;
  width: 30px;
  padding: 0;
  height: 30px;
  border-radius: 50%;
  position: relative;
  align-items: center;
}

.note-list .note-list-header .note-list-header-week-picker .fa-caret-left {
  margin-right: 3px;
}

.note-list .note-list-header .note-list-header-week-picker .fa-caret-right {
  margin-left: 3px;
}

.note-list .note-list-header .note-list-header-week-picker:active {
  background-color: #828a9180 !important;
}

.note-list .note-list-header .note-list-header-week-picker:focus {
  box-shadow: none !important;
}

.note-list .notes-list-container {
  margin-top: 10px;
}

.note-list .note-institution {
  margin-bottom: 8px;
}

.note-list .note-person .note-person-header {
  font-size: 22px;
  font-weight: 900;
}

.note-list .notes-list-container .note-person-wrapper,
.note-list .notes-list-container .note-person-wrapper.narrow,
.note-list .notes-list-container .note-person-wrapper.narrow .note-person {
  margin-bottom: 8px;
}

.note-list .note-institution-container {
  margin-bottom: 4px;
}

.note-list .note-institution {
  font-weight: 600;
  font-size: 15px;
}

.note-list .note-container {
  margin-bottom: 16px;
}

.note-list .note-class-name {
  background-color: #45B7C1;
  color: #ffffff;
  font-size: 13px;
  height: 24px;
  line-height: 24px;
  padding-left: 8px;
}

.note-list .note-item {
  padding: 12px 0 12px 8px;
  font-size: 15px;
  color: #222350;
  white-space: pre-wrap;
  overflow-x: auto;
  background-color: #ffffff;
}

.note-list .note-text img {
  max-width: 100%;
}

/* Content in <widget-html /> is injected therefore /deep/ selector is necessary in production*/
.note-list .note-text /deep/ table tr > td {
  padding: 7px;
  vertical-align: top;
  border: 1px solid #eaebef;
}

.note-list .note-text /deep/ a {
  color: #007a8d;
}

.note-list .mu-provider.narrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
}

.note-list .mu-provider-text {
  font-size: 12px;
  color: #868686;
}

.note-list .mu-provider-btn {
  background-color: transparent;
  padding: 0 0 0 8px;
  border: none;
  border-radius: 0;
}

.note-list .mu-provider-btn:active {
  background-color: transparent;
  border-color: transparent;
}

.note-list .mu-provider-btn:focus {
  box-shadow: none;
}

.note-list .mu-provider-img {
  height: 30px;
}
}
