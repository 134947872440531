

































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timegrid/main.css';

.aula-calendar {
  background: white;
  @media only screen and (min-width: 992px) and (max-width: 1149px) {
    width: $layout-right-normal;
  }
  @media only screen and (min-width: 1150px) {
    width: $layout-right-wide;
  }
  padding: 4px;
  height: 100%;

  &.daily-open {
    @media only screen and (min-width: 992px) and (max-width: 1149px) {
      right: $layout-right-normal;
    }
    @media only screen and (min-width: 1150px) {
      right: $layout-right-wide;
    }
    height: 100%;
  }
  &.daily-close {
    right: 60px;
    z-index: 10;
  }
  &.mobile {
    width: 100%;
    padding: 0;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* hide scrollbars in IE 10+ */
    &::-webkit-scrollbar {
      width: 0px; /* remove scrollbar space */
      background: transparent; /* optional: just make scrollbar invisible */
    }
  }
}

.week-number {
  position: absolute;
  top: 85px;
  left: 6px;
  z-index: 1000;
}
.daily-overview-close {
  position: absolute;
  top: 15px;
  right: 10px;
  padding: 10px;
}
.mobile {
  .week-number {
    top: 16px;
    left: 4px;
  }
}
