






















.headline-alert-variables {
  --background-color: var(--color-grey-dark);
  --padding: 10px;
}
.headline-alert {
  display: flex;
  align-items: center;
  background-color: var(--background-color);
  padding: var(--padding);
}
