

















































.answer-button {
  i {
    font-size: 8px;
  }
}
/deep/.invitee-list {
  min-width: 300px;
  max-height: 40vh;
  overflow-y: auto;
}
