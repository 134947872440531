






































































@import '../../../shared/assets/scss/core/variables.scss';

.vacation-status {
  margin-top: 10px;
  .status {
    font-weight: 700;
    &.header {
      background-color: $color-grey-dark;
      text-transform: uppercase;
      width: 100%;
      padding: 10px 20px;
    }
    .show-more {
      padding: 0px 5px;
      i {
        font-size: 6px;
      }
    }
  }
  .vacation-children {
    margin-top: 10px;
    li {
      list-style: none;
      margin-left: -25px;

      a.disabled {
        pointer-events: none;
        color: $color-grey;
      }
    }
  }
}
