










.vacation-response-col {
  flex-basis: 0;
  flex-grow: 1;
  padding-top: var(--padding-top, 15px);
  padding-bottom: var(--padding-bottom, 15px);
  padding-inline-start: var(--padding-start, 15px);
  padding-inline-end: var(--padding-end, 15px);

  .custom-control.custom-checkbox {
    margin-bottom: 5px;
  }
}
