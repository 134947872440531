
















































































































.invitee-meeting-slot-variables {
  --font-weight: normal;
}

.invitee-meeting-slot {
  display: flex;
  font-weight: var(--font-weight);

  &[aria-selected='true'] {
    --font-weight: bold;
  }

  .slot-time {
    text-transform: capitalize;
    font-weight: var(--font-weight);
  }
  .remaining-slot {
    margin-left: 20px;
  }
  .registered-slots-dropdown {
    margin-left: auto;
  }
  .registered-slots-button {
    span {
      font-weight: var(--font-weight);
    }
    i {
      font-size: 8px;
    }
  }
}
