





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.mobile-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.custom-label {
  display: flex;
  padding-right: 5rem;
  align-items: center;

  @include breakpoint-xs-up {
    padding-right: 6rem;
  }

  @include breakpoint-lg {
    padding-right: 0;
  }
}

.event-settings {
  label {
    @include breakpoint-lg-down() {
      font-weight: normal !important;
    }
  }
  & > div:not(:first-child) {
    margin-top: 12px;
  }
}

.input-group-search-addon {
  font-size: 1.5em;
}

.remove_timeslot {
  margin-top: 15px;
  font-weight: bold;
  i {
    cursor: pointer;
  }
  @include breakpoint-sm-down() {
    margin-left: -15px;
    margin-top: 75px;
  }
}

.has-help-box {
  display: flex;
}

.icon-Aula_search {
  font-size: 18px;
}

.search-module {
  .input-group-prepend {
    --padding-top: 17px;
  }
  /deep/ .aula-search {
    --select-input-tags-padding-right: 28px;
  }
}
