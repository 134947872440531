
































.switch-variables {
  --opacity-disabled: 0.6;
  --background-color-checked: var(--color-primary-dark);
  --background-color: var(--color-grey);
  --color: var(--color-white);
  --height: 31px;
  --width: 60px;
  --transition: background-color 0.3s;
  --space: 2px;
  --opacity: 1;
}

.switch {
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  background-color: var(--background-color);
  transition: var(--transition);
  border-radius: var(--height);
  border: none;
  opacity: var(--opacity);

  &:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: calc(var(--height) - (var(--space) * 2));
    width: calc(var(--height) - (var(--space) * 2));
    left: var(--space);
    bottom: var(--space);
    background-color: var(--color);
    transition: var(--transition);
  }

  &:focus-visible {
    box-shadow: 0 0 2px 1px var(--background-color);
  }

  &[aria-checked='true'] {
    --background-color: var(--background-color-checked);

    &:before {
      transform: translateX(calc(var(--width) - var(--height)));
    }
  }

  &[disabled] {
    --opacity: var(--opacity-disabled);
    cursor: not-allowed;
  }
}
