

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.widget-W0075V0001 {
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.searchIcon {
  font-size: 26px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding-bottom: 10px;
}

.justify-content-md-center {
  justify-content: center !important;
}

#typeAheadContainer {
  position: absolute;
  padding: 0;
  margin-top: -10px;
  /* min-width: 100%; */
  background-color: #fff;
  list-style: none;
  /* border-radius: 4px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  border-radius: 4px;
  max-height: 217px;
  overflow: auto;
}

#typeAheadResult {
  padding: 10px 16px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  display: block;
  margin: 0px;
}

#typeAheadResult:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#typeAheadResult:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: 0;
}

.active {
  background-color: #3aa373;
}

.active span {
  color: white;
}

.activePupil .list-group-item {
  background-color: #3aa373;
  color: white;
}

#pupilItem {
  cursor: pointer;
}

.hoverStudent .list-group-item {
  background-color: rgb(34, 35, 80);
  color: white;
}

#classWrapper {
  max-height: 242px;
  overflow: auto;
  margin-bottom: 10px;
}

#refreshTitle {
  cursor: pointer;
}

.theme-employee .btn-primary.disabled,
.btn-primary:disabled {
  background: #f6f7f8;
  border: solid #d9dfe3;
  border-width: 2px;
  color: #d9dfe3;
  font-weight: bold;
}

.icon-Aula_search {
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: auto;
}

.bold {
  font-weight: bold;
}

input[type="text"].form-control:disabled,
input[type="password"]:disabled,
input[type="email"]:disabled,
input[type="number"]:disabled,
input[type="url"]:disabled,
input[type="tel"]:disabled,
input[type="date"]:disabled,
input[type="time"]:disabled,
select.form-control:disabled,
textarea.form-control:disabled {
  border: solid #d9dfe3;
  border-width: 2px;
}

.tooltip .tooltip-inner {
  color: #222350 !important;
}

.error {
  color: #b50050;
}

.success {
  color: #3aa373;
}

.typeAheadAddInfo {
  color: #549ec7;
}

.searchErrorRow {
  padding-bottom: 10px;
}

.recentDocumentInfo {
  padding-left: 10px;
}

.inline {
  display: inline-block;
}

.showPointer {
  cursor: pointer;
}

.recentDocumentsRowInactive > td,
.showNotAllowed {
  cursor: not-allowed;
}

.container {
  padding-top: 10px;
}

.labelWithSpinner {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  height: 32px;
}

.labelWithSpinner h5 {
  margin: auto 0;
}

.illegalCharactersInfo {
  margin-top: -8px;
  margin-bottom: 10px;
}

.checkboxContainer {
  display: flex;
  justify-content: center;

  .custom-switch {
    margin-top: 3px;
    margin-right: 10px
  }

  > div > label {
    display: inline;
    cursor: inherit;
  }
}

.hideHeader {
  display: none;
}

.showHeader {
  display: unset;
}

.disablePointerEvents {
  pointer-events: none;
}

.smallCellTable {
  padding: 12px 18px;
}

.classText {
  color: #2091A2;
}
}
