<template>
  <div class="pre">
    <p>
      This is your widget
      Click <a href="/aula/">here</a> to see the Aula test platform.
    </p>
    <div>
      <div v-for="(prop, index) in propsList" :key="index">
        <span class="bold">
          {{prop.key}}
        </span>:
        <span>
          {{prop.value}}
        </span>
      </div>
      <br/>
      <button type="button" v-if="getAulaToken" @click="updateAulaToken">
        get aula token
      </button>
      &nbsp;
      <button type="button" v-if="triggerStepUp" @click="runStepUp">
        trigger step up
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, PropType} from "vue";
import {AxiosInstance} from "axios";
import {Moment} from "moment";

export default {
  props: {
    widgetID: String,
    axios: {
      type: Function as PropType<AxiosInstance>
    },
    moment: {
      type: Object as PropType<Moment>
    },
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    group: String,
    source: String,
    institutionCode: String,
    triggerStepUp: Function,
    assuranceLevel: Number,
    childFilter: {
      type: Array as PropType<string[]>
    },
    institutionFilter: {
      type: Array as PropType<string[]>
    },
    getAulaToken: Function,
    aulaToken: String,
  },
  setup(props: any) {
    const propKeys = computed(() => {
      return Object.keys(props).filter((key) => !['axios', 'moment', 'getAulaToken', 'triggerStepUp'].includes(key));
    });
    const propsList = computed(() => {
      return propKeys.value.filter((key: string) => props[key]).map((key: any) => {
        return {
          key,
          value: props[key],
        };
      })
    });
    const updateAulaToken = () => {
        props.getAulaToken();
    };
    const runStepUp = () => {
        props.triggerStepUp();

    };

    return {
      propKeys,
      propsList,
      updateAulaToken,
      runStepUp
    }
  }
}
</script>
<style scoped>
.bold {
  font-weight: bold;
}
</style>
