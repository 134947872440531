










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.response-timeslot {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  .timeslot {
    min-width: 450px;
    margin: 0 1px;
    @include breakpoint-lg-down() {
      min-width: unset;
      width: 100%;
      padding-bottom: 50px;
    }
    .header {
      width: 100%;
      text-align: center;
      font-weight: bold;
      padding: 10px 40px;
      background-color: $color-grey-dark;

      &::first-letter {
        text-transform: capitalize;
      }
    }
    .slot-picker-time-break {
      color: $color-help-text;
    }
    .slot-picker {
      &.accepted {
        cursor: default;
        color: $color-grey;
        font-weight: normal;
        .checkbox {
          visibility: hidden;
        }
      }
      display: flex;
      align-content: center;
      padding: 5px 0;
      margin-left: 20px;
      .radio {
        cursor: pointer;
        width: 25px;
        .icon-Aula_search {
          line-height: 30px;
        }
      }
      .time,
      .time {
        margin: 0 5px;
        font-weight: bold;
      }
    }
  }
  /deep/ .slot-picker {
    .dropdown-menu {
      &.show {
        max-width: calc(100vw - 220px);
        height: 500px;
        overflow: hidden;
        overflow-y: scroll;
        height: fit-content;
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;

        @include breakpoint-lg() {
          max-width: 300px;
        }

        a {
          font-size: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .theme-employee & {
            color: $color-primary-darker-employee;
          }
          .theme-guardian & {
            color: $color-primary-darker-guardian;
          }
          .theme-child & {
            color: $color-primary-darker-child;
          }
          .theme-admin & {
            color: $color-primary-darker-admin;
          }
        }
      }
    }
  }
}

.response-header {
  --questionmark-icon-background-color: var(--color-grey-dark);

  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-grey-dark;
  margin-bottom: 10px;
  .response-title {
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .response-persons {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .participant {
      flex-grow: 1;
      .title {
        font-weight: bold;
      }
      .organizer {
        flex-grow: 1;
        border-bottom: 1px solid var(--color-grey-dark);
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.response-description {
  margin-bottom: 10px;
}

.aula-close {
  cursor: pointer;
  float: right;
}

.theme-employee {
  .slot-picker.selected {
    color: $color-primary-base-employee;
  }
}

.theme-child {
  .slot-picker.selected {
    color: $color-primary-base-child;
  }
}

.theme-admin {
  .slot-picker.selected {
    color: $color-primary-base-admin;
  }
}

.theme-guardian {
  .slot-picker.selected {
    color: $color-primary-base-guardian;
  }
}

.mobile-footer {
  background: $color-grey-base;
  padding: 10px;
  width: 100%;
  transform: translateX(-20px);
  position: fixed;
  z-index: $aula-modal-button-z-index;
  bottom: 0;
  text-align: center;

  button {
    text-transform: uppercase;
    font-size: 12px;

    .theme-employee & {
      color: $color-primary-dark-employee;
    }

    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }

    .theme-child & {
      color: $color-primary-dark-child;
    }
  }
}
